.Pastpaperexam .text-end {
  text-align: right !important;
  position: absolute;
  right: 0;
}
.Pastpaperexam-tab-main .nav-link.active {
  border-radius: 0px 0px 15px 15px;
  background: #fff !important;
  box-shadow: 4px 4px 7.6px 0px rgba(0, 0, 0, 0.25);
  padding: 22px;
  min-width: 229.625px;
  width: max-content;
  min-height: 66px;
  height: auto;
  z-index: 40 !important;
}
.Pastpaperexam-tab-main .nav-link {
  min-width: 229.625px;
  width: max-content;
  border-radius: 0px 0px 15px 15px;
  box-shadow: 4px 4px 7.6px 0px rgba(0, 0, 0, 0.25);
  padding: 17px 26px;
  position: relative;
}
.Pastpaperexam-tab-main {
  border: none;
  flex-wrap: nowrap;
  min-height: 130px;
  overflow-x: scroll;
  height: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.Pastpaperexam-tab-main::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}
.Pastpaperexam-tab-herder img {
  width: 33px;
  height: 33px;
}
.Pastpaperexam-tab-herder {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: start;
}
.Pastpaperexam-tab-herder h4 {
  color: #000;
  font-family: Nunito;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 16.8px; /* 152.727% */
}
.Pastpaperexam-tab-herder p {
  color: #4d4d4d;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 9.68px;
  margin-bottom: 0;
}
.margin-left-10 {
  margin-left: 10px;
}
.Pastpaperexam-tab-main .nav-tabs.nav-item:nth-child(1) .nav-link {
  background: #e0fff0;
  z-index: 19;
}
.Pastpaperexam-tab-main .nav-tabs.nav-item:nth-child(2) .nav-link {
  background: #87cefa;
  left: -30px;
  z-index: 18;
}
.Pastpaperexam-tab-main .nav-tabs.nav-item:nth-child(3) .nav-link {
  background: #c6e8d2;
  left: -60px;
  z-index: 17;
}
.Pastpaperexam-tab-main .nav-tabs.nav-item:nth-child(4) .nav-link {
  background: #fff9c4;
  left: -90px;
  z-index: 16;
}
.Pastpaperexam-tab-main .nav-tabs.nav-item:nth-child(5) .nav-link {
  background: #e6e6ff;
  left: -120px;
  z-index: 15;
}
.Pastpaperexam-tab-main .nav-tabs.nav-item:nth-child(6) .nav-link {
  background: #f5f5f5;
  left: -150px;
  z-index: 14;
}
.Pastpaperexam-tab-main .nav-tabs.nav-item:nth-child(7) .nav-link {
  background: #e0ffff;
  left: -180px;
  z-index: 13;
}
.Pastpaperexam-tab-main .nav-tabs.nav-item:nth-child(8) .nav-link {
  background: #ffefd5;
  left: -210px;
  z-index: 12;
}
.Pastpaperexam-tab-main .nav-tabs.nav-item:nth-child(9) .nav-link {
  background: #f7f7f7;
  left: -240px;
  z-index: 11;
}
.Pastpaperexamcontent-page-filter .form-select {
  border-radius: 5px;
  border: none;
  background-color: #e0fff000;
  font-size: 12px;
}
.Pastpaperexamcontent-page-filter .dropdown {
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.Pastpaperexamcontent-page-filter .dropdown button {
  width: 100%;
  display: flex;
  background-color: #0000 !important;
  border-color: #0000 !important;
  color: #212529;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  padding-left: 2px;
  padding-right: 4px;
}
.Pastpaperexamcontent-page-filter .dropdown label.form-label {
  font-size: 10px;
  margin-bottom: 0;
  margin-left: 5px;
}
.Pastpaperexamcontent-page-filter .dropdown-toggle::after {
  margin-top: -8px;
  width: 8px;
}
.btn.show,
.btn:first-child:active {
  background-color: #0000 !important;
  border-color: #0000 !important;
  color: #212529;
}
.Pastpaperexamcontent-page-filter .col-sm-2,
.Pastpaperexamcontent-page-filter .col-sm-1 {
  padding-left: 2px;
  padding-right: 2px;
}
.Select-Category-lable label.form-label {
  font-size: 7px;
  margin-bottom: 0;
  margin-left: 5px;
}
.pastpaperexam_page .dropdown-menu.show {
  transform: translate3d(0px, 34px, 0px) !important;
  /* width: 296px; */
  /* height: 392px; */
  overflow: auto;
}
.filter-drop-menu {
  display: flex;
  width: 100%;
}
.form-select:focus {
  box-shadow: none !important;
}
.Pastpaperexamcontent-page-filter-content {
  margin-top: 50px;
  margin-bottom: 100px;
}
.Pastpaperexamcontent-page-filter-content-left-header,
.Pastpaperexamcontent-page-filter-content-right-header {
  border-radius: 4px;
  padding: 40px 20px 20px 20px;
}
.Pastpaperexamcontent-page-filter-content-left-header-content,
.Pastpaperexamcontent-page-filter-content-right-header-content {
  display: flex;
  justify-content: flex-end;
}
.Pastpaperexamcontent-page-filter-content-left-header-content p,
.Pastpaperexamcontent-page-filter-content-right-header-content p {
  margin-bottom: 0;
  margin-right: 10px;
  font-size: 12px;
  cursor: pointer;
}
.Pastpaperexamcontent-page-filter-content-left-header-content p span {
  background: #d4d4d4;
  padding: 2px 8px;
  border-radius: 50px;
}
.Pastpaperexamcontent-page-filter-content-right-header-content p.active {
  color: #ce140d;
}
.Pastpaperexamcontent-page-filter-content-right-content-header {
  background-color: #f7f9fc;
  padding: 10px 12px;
}
.Pastpaperexamcontent-page-filter-content-right-content-header p {
  margin-bottom: 0;
  margin-right: 10px;
}
.Pastpaperexamcontent-page-filter-content-right-content-header p {
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-left: 20px;
}
.topic-color {
  color: #910e1f;
}
.Pastpaperexamcontent-page-filter-content-right-content {
  margin: 3px;
}
.Pastpaperexamcontent-page-filter-content-right {
  border: 1px solid #e1e9f1;
}
.Pastpaperexamcontent-page-filter-content-left-content ul {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
.Pastpaperexamcontent-page-filter-content-left-content ul li {
  color: #323a46;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  background: #f7f9fc;
  padding: 15px 16px;
}
.Pastpaperexamcontent-page-filter-content-left {
  border: 1px solid #e1e9f1;
  height: 100%;
}
.Pastpaperexamcontent-page-filter-content-left-content {
  margin: 3px;
  height: 89%;
}
.Pagination-bar {
  margin-top: 5px;
}
.Pagination-bar .page-item.active span.page-link {
  color: #fff;
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
}
.Pagination-bar .page-item a.page-link {
  color: #323a46;
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
}
.Pastpaperexamcontent-page-filter-content-right-content-img {
  padding: 20px;
}
.pastpaperexam_page .tab-content {
  height: 100% !important;
}
.filter-drop-menu-heading h5 {
  margin-bottom: 0;
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.filter-drop-menu-p {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 20px;
}
.filter-drop-menu-p p {
  margin-right: 20px;
  margin-bottom: 10px;
  color: #4d7a14;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-decoration-line: underline;
}
.filter-drop-menu-heading ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}
.filter-drop-menu-heading ul li {
  font-size: 12px;
  padding: 6px 22px;
}

/* tab-1 */
.tab_1 .Pastpaperexamcontent-page-filter .dropdown {
  border: 1px solid #d5d5d5;
  background-color: #e0fff0;
}
.tab_1 .Pastpaperexamcontent-page-filter-content-right-header,
.tab_1 .Pastpaperexamcontent-page-filter-content-left-header {
  background-color: #e0fff0;
}
.tab_1 .Pastpaperexamcontent-page-filter-content-right-content,
.tab_1 .Pastpaperexamcontent-page-filter-content-left-content {
  box-shadow: #e0fff0 0px 1px 4px, #e0fff0 0px 0px 0px 3px;
  border: 8px solid #e0fff0;
}
.tab_1 .Pagination-bar .page-item.active span.page-link {
  border: 1px solid #e0fff0;
  background: #e0fff0;
  color: #000;
}
.tab_1 .mobile-view-Pagination-bar ul {
  background-color: #e0fff0;
}
/* tab-2 */
.tab_2 .Pastpaperexamcontent-page-filter .dropdown {
  border: 1px solid #87cefa;
  background-color: #87cefa;
}
.tab_2 .Pastpaperexamcontent-page-filter-content-right-header,
.tab_2 .Pastpaperexamcontent-page-filter-content-left-header {
  background-color: #87cefa;
}
.tab_2 .Pastpaperexamcontent-page-filter-content-right-content,
.tab_2 .Pastpaperexamcontent-page-filter-content-left-content {
  box-shadow: #87cefa 0px 1px 4px, #87cefa 0px 0px 0px 3px;
  border: 8px solid #87cefa;
}
.tab_2 .Pagination-bar .page-item.active span.page-link {
  border: 1px solid #87cefa;
  background: #87cefa;
}
.tab_2 .mobile-view-Pagination-bar ul {
  background-color: #87cefa;
}
/* tab-3 */
.tab_3 .Pastpaperexamcontent-page-filter .dropdown {
  border: 1px solid #c6e8d2;
  background-color: #c6e8d2;
}
.tab_3 .Pastpaperexamcontent-page-filter-content-right-header,
.tab_3 .Pastpaperexamcontent-page-filter-content-left-header {
  background-color: #c6e8d2;
}
.tab_3 .Pastpaperexamcontent-page-filter-content-right-content,
.tab_3 .Pastpaperexamcontent-page-filter-content-left-content {
  box-shadow: #c6e8d2 0px 1px 4px, #c6e8d2 0px 0px 0px 3px;
  border: 8px solid #c6e8d2;
}
.tab_3 .Pagination-bar .page-item.active span.page-link {
  border: 1px solid #c6e8d2;
  background: #c6e8d2;
}
.tab_3 .mobile-view-Pagination-bar ul {
  background-color: #c6e8d2;
}
/* tab-4 */
.tab_4 .Pastpaperexamcontent-page-filter .dropdown {
  border: 1px solid #fff9c4;
  background-color: #fff9c4;
}
.tab_4 .Pastpaperexamcontent-page-filter-content-right-header,
.tab_4 .Pastpaperexamcontent-page-filter-content-left-header {
  background-color: #fff9c4;
}
.tab_4 .Pastpaperexamcontent-page-filter-content-right-content,
.tab_4 .Pastpaperexamcontent-page-filter-content-left-content {
  box-shadow: #fff9c4 0px 1px 4px, #fff9c4 0px 0px 0px 3px;
  border: 8px solid #fff9c4;
}
.tab_4 .Pagination-bar .page-item.active span.page-link {
  border: 1px solid #fff9c4;
  background: #fff9c4;
}
.tab_4 .mobile-view-Pagination-bar ul {
  background-color: #fff9c4;
}
/* tab-5 */
.tab_5 .Pastpaperexamcontent-page-filter .dropdown {
  border: 1px solid #e6e6ff;
  background-color: #e6e6ff;
}
.tab_5 .Pastpaperexamcontent-page-filter-content-right-header,
.tab_5 .Pastpaperexamcontent-page-filter-content-left-header {
  background-color: #e6e6ff;
}
.tab_5 .Pastpaperexamcontent-page-filter-content-right-content,
.tab_5 .Pastpaperexamcontent-page-filter-content-left-content {
  box-shadow: #e6e6ff 0px 1px 4px, #e6e6ff 0px 0px 0px 3px;
  border: 8px solid #e6e6ff;
}
.tab_5 .Pagination-bar .page-item.active span.page-link {
  border: 1px solid #e6e6ff;
  background: #e6e6ff;
}
.tab_5 .mobile-view-Pagination-bar ul {
  background-color: #e6e6ff;
}
/* tab-6 */
.tab_6 .Pastpaperexamcontent-page-filter .dropdown {
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
}
.tab_6 .Pastpaperexamcontent-page-filter-content-right-header,
.tab_6 .Pastpaperexamcontent-page-filter-content-left-header {
  background-color: #f5f5f5;
}
.tab_6 .Pastpaperexamcontent-page-filter-content-right-content,
.tab_6 .Pastpaperexamcontent-page-filter-content-left-content {
  box-shadow: #f5f5f5 0px 1px 4px, #f5f5f5 0px 0px 0px 3px;
  border: 8px solid #f5f5f5;
}
.tab_6 .Pagination-bar .page-item.active span.page-link {
  border: 1px solid #f5f5f5;
  background: #f5f5f5;
}
.tab_6 .mobile-view-Pagination-bar ul {
  background-color: #f5f5f5;
}
/* tab-7 */
.tab_7 .Pastpaperexamcontent-page-filter .dropdown {
  border: 1px solid #e0ffff;
  background-color: #e0ffff;
}
.tab_7 .Pastpaperexamcontent-page-filter-content-right-header,
.tab_7 .Pastpaperexamcontent-page-filter-content-left-header {
  background-color: #e0ffff;
}
.tab_7 .Pastpaperexamcontent-page-filter-content-right-content,
.tab_7 .Pastpaperexamcontent-page-filter-content-left-content {
  box-shadow: #e0ffff 0px 1px 4px, #e0ffff 0px 0px 0px 3px;
  border: 8px solid #e0ffff;
}
.tab_7 .Pagination-bar .page-item.active span.page-link {
  border: 1px solid #e0ffff;
  background: #e0ffff;
}
.tab_7 .mobile-view-Pagination-bar ul {
  background-color: #e0ffff;
}
/* tab-8 */
.tab_8 .Pastpaperexamcontent-page-filter .dropdown {
  border: 1px solid #ffefd5;
  background-color: #ffefd5;
}
.tab_8 .Pastpaperexamcontent-page-filter-content-right-header,
.tab_8 .Pastpaperexamcontent-page-filter-content-left-header {
  background-color: #ffefd5;
}
.tab_8 .Pastpaperexamcontent-page-filter-content-right-content,
.tab_8 .Pastpaperexamcontent-page-filter-content-left-content {
  box-shadow: #ffefd5 0px 1px 4px, #ffefd5 0px 0px 0px 3px;
  border: 8px solid #ffefd5;
}
.tab_8 .Pagination-bar .page-item.active span.page-link {
  border: 1px solid #ffefd5;
  background: #ffefd5;
}
.tab_7 .mobile-view-Pagination-bar ul {
  background-color: #ffefd5;
}
/* tab-9 */
.tab_9 .Pastpaperexamcontent-page-filter .dropdown {
  border: 1px solid #f7f7f7;
  background-color: #f7f7f7;
}
.tab_9 .Pastpaperexamcontent-page-filter-content-right-header,
.tab_9 .Pastpaperexamcontent-page-filter-content-left-header {
  background-color: #f7f7f7;
}
.tab_9 .Pastpaperexamcontent-page-filter-content-right-content,
.tab_9 .Pastpaperexamcontent-page-filter-content-left-content {
  box-shadow: #f7f7f7 0px 1px 4px, #f7f7f7 0px 0px 0px 3px;
  border: 8px solid #f7f7f7;
}
.tab_9 .Pagination-bar .page-item.active span.page-link {
  border: 1px solid #f7f7f7;
  background: #f7f7f7;
}
.tab_9 .mobile-view-Pagination-bar ul {
  background-color: #f7f7f7;
}

/* new style */
.offcanvas {
  background-color: #0000;
}
.offcanvas-backdrop {
  position: fixed;
  top: 62px;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-body {
  padding-top: 0;
  top: 62px;
  position: relative;
}
.offcanvas-header {
  position: absolute;
  left: 320px;
  top: 10px;
  padding: 0;
}
.top-menu-button-show.and.hide img {
  width: auto;
}
.top-menu-button-show.and.hide {
  margin-top: 5px;
  margin-bottom: 25px;
}
.top-menu-button-show.and.hide button {
  width: auto;
  background: #0000 !important;
  border: none;
  box-shadow: rgb(255 255 255 / 25%) 0px 30px 60px -12px inset,
    rgb(255 255 255 / 30%) 0px 18px 36px -18px inset;
  border-radius: 50%;
}
.filtter-top-menu-style-mobile-view .dropdown {
  display: inline-flex;
  align-items: center;
  margin: 5px;
}
.filtter-top-menu-style-mobile-view .dropdown-toggle::after {
  margin-left: 1.255em;
}
.filtter-top-menu-style-mobile-view .dropdown button {
  font-size: 12px;
  padding-left: 12px;
  padding-right: 12px;
}
.filtter-top-menu-style-mobile-view .dropdown .form-label {
  font-size: 12px;
  margin-left: 12px;
}
.Pastpaperexamcontent-page-filter-content-left-content
  .Pastpaperexamcontent-mobile-view {
  border: none;
  flex-wrap: nowrap;
  overflow-x: scroll;
  height: auto;
  scrollbar-width: none;
  display: flex;
}
.mobile-view
  .Pastpaperexamcontent-page-filter-content-left-content
  .Pastpaperexamcontent-mobile-view
  li {
  min-width: 200px;
  background: #fff;
  color: #557b21;
}
.mobile-view-Pagination-bar ul li {
  padding: 0px;
}
.mobile-view-Pagination-bar ul {
  /* background-color: #e0fff0; */
  padding: 8px 0;
}
.mobile-view-Pagination-bar {
  margin-top: 0;
  background-color: #e1e9f1;
}
.mobile-view .Pastpaperexamcontent-page-filter-content-left {
  /* border: 8px solid #e0fff0; */
  height: 100%;
}
.Pastpaperexamcontent-bottom-margen-mobile-view {
  margin-bottom: 10px;
}
.Pastpaperexamcontent-top-content-2
  .Pastpaperexamcontent-page-filter-content-right-header-content,
.Pastpaperexamcontent-top-content-1
  .Pastpaperexamcontent-page-filter-content-right-header-content {
  justify-content: space-between;
}
.Pastpaperexamcontent-top-content-1
  .Pastpaperexamcontent-page-filter-content-right-header {
  background: #0000;
}
.Pastpaperexamcontent-top-content-2
  .Pastpaperexamcontent-page-filter-content-right-header {
  padding: 13px;
}
/* .Pastpaperexamcontent-top-content-2
  .Pastpaperexamcontent-page-filter-content-right {
  border: 8px solid #e0fff0;
} */

/* new style */
.filter-drop-menu .filter-drop-menu-heading {
  display: flex;
}
.header-number-project {
  position: fixed;
  width: 100%;
  z-index: 99;
  background: #fff;
}
._lightMode_ .header-number-project {
  background: #2e2e2e;
}
object.object_pdf_question {
  width: 100%;
  height: 100vh;
}

/* new style */
.dropdown-button-fillter-style {
  width: max-content !important;
}
.dropdown-button-fillter-style-main-div {
  padding: 0px !important;
}
.dropdown-button-fillter-style button {
  color: #4d7a14 !important;
  font-family: Inter;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  height: auto;
  text-decoration-line: underline;
  padding: 0 !important;
  line-height: 20px;
}
.filter-drop-filter-main-box-style {
  padding: 0 10px;
}
