.top-header-past-paper {
    background: linear-gradient(180deg, #F3E6FD 0%, #FBFFF5 100%);
}
.ps-box {
    border-radius: 13px;
    background: linear-gradient(180deg, #F3E6FD 0%, #FBFFF5 100%);
    padding: 30px;
    display: block;
}
.ps-box h4 {
    color: #000;
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
/* dark */
._lightMode_ .top-header-past-paper  {
    background: #0000;
}
._lightMode_ .ps-box {
   background: #494949;
}
._lightMode_ .ps-box h4 {
    color: #fff;
}
._lightMode_ .past-paper-filter-top-heading {
    background: #494949;
}
.past-paper-filter-top-heading {
    border-radius: 13px;
    background: linear-gradient(180deg, #F3E6FD 0%, #FBFFF5 100%);
    padding: 30px 30px;
}
.past-paper-main-box-style {
    border-radius: 5px;
    width: 100%;
    background: linear-gradient(180deg, #F3E6FD 0%, #FBFFF5 100%);
    padding: 20px;
    margin-bottom: 2rem;
}
._lightMode_ .past-paper-main-box-style {
    background: #494949;
}
.past-paper-filter-top-heading h4 a{
    font-weight: 600 !important;
    color: #000;
    font-family: Nunito;
    font-size: 22px;
    font-style: normal;
    line-height: normal;

}
._lightMode_ .past-paper-filter-top-heading h4 a {
    color: #fff;
}
.past-paper-main-box-style {
background: linear-gradient(180deg, #EDDFF7 0%, #FFF 100%);
}
._lightMode_ .past-paper-main-box-style {
    background: #494949;
    }
    .past-paper{
        background: linear-gradient(180deg, #F3E6FD 0%, #FBFEF6 100%);
    }

.past-paper-row{
    width: 1500px;
    position: relative;
    z-index: 200;
}


.past-paper-button {
    font-size: 20px;
    font-family: "Nunito", sans-serif;
    color: black;
    padding: 5px 10px;
    background-color: rgba(63,168,26, 0.4); /* Darker blue on hover */
    border-radius: 12px;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-weight: 600;
    border: none;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
    box-shadow: 0 0 10px rgba(112, 112, 112, 0.7); /* Blue glow */
    margin-right: 20px;
  }
  
  .past-paper-button:last-child {
    margin-right: 0; /* Remove margin from the last button */
  }
  
  .past-paper-button:hover {
    background-color: rgba(63, 168, 26, 0.45); /* #3FA81A with 25% opacity */
    color: #000000; /* White text on hover */
  }

  .button-icon {
    width: 50px; /* Adjust the size as needed */
    height:auto; 
    margin-right: 8px; /* Space between the icon and text */
    vertical-align: middle; /* Align icon vertically with text */
  }