#VisaChipCardVideo {
    width: 100%;
    border-radius: 8px;
}
.video_popup_main {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
}
.video_colse_icon {
    position: absolute;
    right: 0;
    top: auto;
    cursor: poNunito;
    width: 25px;
    height: 25px;
    background: #000;
    color: #fff;
    display: flex;
    font-size: 20px;
    align-items: center;
    border-radius: 0px 0px 0px 14px;
    justify-content: center;
    padding-bottom: 7px;
    z-index: 2;
}
.main-video-popup {
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
/* .model-body {
    background: #fff;
    border-radius: 8px;
} */
.main-photo-popup_video {
    position: relative;
    margin: auto;
    width: auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    height: 90%;
    background: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    border-radius: 8px;
}
@media only screen and (max-width: 767px) { 
    .main-photo-popup_video {
        width: 90%;
        height: auto;
    } 
}