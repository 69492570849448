.myprofile-heading h2 {
  color: #5b9311;
  font-family: Nunito;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.filter_myprofile_top ul button {
  color: #000;
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: #0000;
  border: none;
  margin-right: 30px;
}

.filter_myprofile_top ul {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 20px;
  padding-left: 0;
  margin-bottom: 0;
}

.filter-item.Password {
  width: 100%;
}

.myprofile-password {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  border-bottom: 1px solid #e6e6e6;
}
.myprofile-password .text-start {
  margin-bottom: 1.5rem;
}

.myprofile-password-update {
  display: flex;
  align-items: center;
}

.myprofile-password p {
  color: #000;
  text-align: center;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}

.myprofile-password-update .btn-Cancel {
  color: #5b9311;
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 30px;
}

.myprofile-password-update .btn-primary {
  font-weight: 500;
  border-radius: 25px;
}

.myprofile-password-from label {
  width: auto;
  min-width: 160px;
}

.myprofile-password-from .form-control {
  border-radius: 6px;
  border: 1px solid #949292;
  height: 40px;
  max-width: 300px;
  width: 100%;
}

.myprofile-password-from input::placeholder {
  color: #8d8989;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.myprofile-password-from {
  border-bottom: 1px solid #e6e6e6;
}

.bt-border-none {
  border-bottom: none !important;
}

.filter-item.Billing {
  width: 100%;
}

.filter-item.Profile {
  width: 100%;
}
.filter-item.Notifications {
  width: 100%;
}

.update-p-text {
  font-size: 14px !important;
}

.myprofile_update_main .form-control {
  border-radius: 6px;
  border: 1px solid #949292;
  height: 40px;
  width: 100%;
  margin-bottom: 1.5rem;
}

.myprofile_update_main {
  border-bottom: 1px solid #e6e6e6;
}
.profile_img {
  width: 120px;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}
.upi_payment_left {
  margin-bottom: 1.5rem;
}
.myprofile-password-update {
  margin-top: 1.5rem;
}

.parent {
  border-radius: 7px;
  border: 1px solid #949292;
  background: #fff;
}
.file-upload {
  position: relative;
  cursor: pointer;
  text-align: center;
  padding: 27px;
}
.file-upload input {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}
.file-upload img {
  width: 24px;
}
.profile_img_upload_input {
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile_img_upload_input p {
  margin: 5px 10px;
  color: #615f5f;
  text-align: center;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.profile_img_upload_input img {
  margin: 0 10px;
}
.upload_file_type_text {
  margin: 5px 0;
  color: #615f5f;
  text-align: center;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.col-form-label p {
  color: #595959;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.flags_select_input button {
  border-radius: 5px;
  border: 1px solid #949292;
  background-color: #f2f2f2;
  color: #8d8989;
  font-family: Nunito;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 40px;
}
.checkbox_notifications label {
  color: #2f2f2f;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-width: 0;
  margin-left: 10px;
}
.checkbox_notifications {
  display: flex;
  align-items: center;
}

.checkbox_notifications input[type="checkbox"] {
  width: 20px; /* Increase the width */
  height: 20px; /* Increase the height */
  cursor: pointer; /* Optional: make cursor pointer */
}

.checkbox_notifications label {
  font-size: 16px; /* Adjust the label font size */
  margin-left: 10px; /* Space between checkbox and label */
}
.Billing_table th {
  color: #000;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: #0000;
  padding: 20px 0;
  border-color: #e6e6e6;
}
.Billing_table td {
  color: #000;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  background-color: #0000;
  padding: 20px 0;
  border-color: #e6e6e6;
}
.upi_payment_left img {
  width: 80px;
  margin: 0 20px;
  border-radius: 20px;
  border: 3px solid #b5c1c1;
  background: #fff;
  padding: 10px;
  height: 80px;
  object-fit: contain;
}
.upi_payment_left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.myprofile-card p {
  color: #000;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}
.upi_payment_right img {
  height: 220px;
  width: auto;
}
.upi_payment_right {
  text-align: end;
}
/* dark */
._lightMode_ .filter_myprofile_top ul button {
  color: #fff;
}
._lightMode_ .myprofile-password p {
  color: #fff;
}
._lightMode_ .col-form-label {
  color: #fff;
}
._lightMode_ .col-form-label p {
  color: #fff;
}
._lightMode_ .Billing_table th {
  color: #fff;
}
._lightMode_ .Billing_table td {
  color: #fff;
}
._lightMode_ .myprofile-card p {
  color: #fff;
}
.Country_code_input input {
  border: none;
}
.Country_code_input input:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

.glass-background {
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(10px);
  border-radius: 100px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 75%;
  margin-left: 11.5vw;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  position: relative;
  z-index: 20;
}

.eye-icon {
  position: absolute;
  right: 10px; /* Adjust to your liking */
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 18px;
  color: #000;
}

.eye-icon:hover {
  color: #555;
}

.success-alert {
  background-color: #d4edda; /* Soft green background */
  color: #155724; /* Dark green text */
  border: 2px solid #c3e6cb; /* Green border */
  padding: 10px;
  border-radius: 5px;

  /* Start invisible and use transitions for fade in/out */
  opacity: 0;
  transition: opacity 0.4s ease-in-out;

  margin-bottom: 30px;
}

/* When we have a success message, show it */
.success-alert.show {
  opacity: 1;
}

.Myprofile-section {
  margin-top: 80px;
  padding-top: 20px;
}

/* Optional: Adjust the toggle switch size and spacing */
.toggle-renew-switch .form-check-input {
  width: 2.5em;
  height: 1.4em;
}

.toggle-renew-switch .form-check-input:checked {
  background-color: #5b9311;
  border-color: #5b9311;
}

/* Center the switch within its table cell */
td > .form-check {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .Myprofile-section {
    margin-top: 0px;
    padding-top: 0px;
  }

  .glass-background {
    width: 95%;
    margin: 0 auto;
    padding: 15px;
    border-radius: 20px;
  }

  /* Reduce heading size for smaller screens */
  .myprofile-heading h2 {
    font-size: 30px;
  }

  /* Adjust button font size and spacing */
  .filter_myprofile_top ul button {
    font-size: 14px;
    margin-right: 12px;
  }

  /* Optionally adjust modal widths if xl modals appear too large */
  .modal-xl {
    max-width: 90%;
  }
}

@media (max-width: 850px) {
  /* Adjust the logo size */
  .Myprofile-section {
    margin-top: 0px;
    padding-top: 0px;
  }

  /* Glass background adjustments for a more centered, spacious look */
  .glass-background {
    width: 90%; /* Slightly wider to use available space */
    margin: 0 auto; /* Center horizontally */
    padding: 25px; /* Increase padding for better spacing */
    border-radius: 20px; /* Soften the edges a bit */

    margin-bottom: 50px;
  }

  /* Heading: reduce the size a bit and add extra spacing */
  .myprofile-heading h2 {
    font-size: 35px; /* Smaller than desktop but still prominent */
    margin-bottom: 20px; /* Extra space below the heading */
  }

  /* Filter buttons: Adjust font size and margin to avoid crowding */
  .filter_myprofile_top ul button {
    font-size: 16px;
    margin-right: 20px; /* Reduce horizontal spacing compared to desktop */
    margin-bottom: 10px; /* Allow wrapping with a gap if needed */
  }

  /* Form controls: Allow full width to prevent cramping */
  .myprofile-password-from .form-control,
  .myprofile_update_main .form-control {
    max-width: 100%;
  }

  /* Profile image: Scale down for better fit */
  .profile_img {
    width: 100px;
    margin: 20px auto; /* Center the image with vertical spacing */
  }

  /* Billing table: Adjust cell padding and font size */
  .Billing_table th,
  .Billing_table td {
    padding: 15px 0; /* Reduce padding if needed, yet maintain readability */
    font-size: 16px;
  }

  /* UPI payment images: Scale down slightly */
  .upi_payment_left img {
    width: 60px;
    height: 60px;
    margin: 0 10px;
  }

  /* Ensure flex containers wrap nicely and center content */
  .myprofile-password,
  .myprofile-password-update,
  .profile_img_upload_input {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1100px) {
  /* Adjust the logo size */
  .Myprofile-section {
    margin-top: 0px;
    padding-top: 0px;
  }

  /* Glass background adjustments for a more centered, spacious look */
  .glass-background {
    width: 90%; /* Slightly wider to use available space */
    margin: 0 auto; /* Center horizontally */
    padding: 25px; /* Increase padding for better spacing */
    border-radius: 20px; /* Soften the edges a bit */
    margin-top: 80px;
    margin-bottom: 50px;
  }

  /* Heading: reduce the size a bit and add extra spacing */
  .myprofile-heading h2 {
    font-size: 35px; /* Smaller than desktop but still prominent */
    margin-bottom: 20px; /* Extra space below the heading */
  }

  /* Filter buttons: Adjust font size and margin to avoid crowding */
  .filter_myprofile_top ul button {
    font-size: 16px;
    margin-right: 20px; /* Reduce horizontal spacing compared to desktop */
    margin-bottom: 10px; /* Allow wrapping with a gap if needed */
  }

  /* Form controls: Allow full width to prevent cramping */
  .myprofile-password-from .form-control,
  .myprofile_update_main .form-control {
    max-width: 100%;
  }

  /* Profile image: Scale down for better fit */
  .profile_img {
    width: 100px;
    margin: 20px auto; /* Center the image with vertical spacing */
  }

  /* Billing table: Adjust cell padding and font size */
  .Billing_table th,
  .Billing_table td {
    padding: 15px 0; /* Reduce padding if needed, yet maintain readability */
    font-size: 16px;
  }

  /* UPI payment images: Scale down slightly */
  .upi_payment_left img {
    width: 60px;
    height: 60px;
    margin: 0 10px;
  }

  /* Ensure flex containers wrap nicely and center content */
  .myprofile-password,
  .myprofile-password-update,
  .profile_img_upload_input {
    flex-wrap: wrap;
    justify-content: center;
  }
}
