.popup-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

.popup-content {
  background-color: #fff;
  border-radius: 8px;
  padding: 10px; /* Reduced padding */
  max-width: 600px;
  width: 90%;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.35);
  position: relative;
}

.popup-header {
  display: flex;
  justify-content: flex-end;
  border-bottom: none;
  padding-bottom: 0;
}

.popup-close-button {
  background: none;
  border: none;
  font-size: 2.3rem; /* Increased size of the close button */
  cursor: pointer;
  color: black;
}

.popup-close-button:hover {
  opacity: 0.7;
}

.popup-body {
  padding: 10px; /* Reduced padding inside the body */
}

.popup-text {
  font-size: 1.2rem;
  line-height: 1.5;
}

@media (max-width: 767px) {
  .popup-content {
    width: 95%;
  }
}
