.section-1-left.privacy_policy:before {
    content: "";
    background: #fff;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: auto;
    position: absolute;
    box-shadow: 7px 7px 3px #6ccd7c;
    padding: 30px 0px 20px 0px;
    width: 100%;
    border-radius: 200px 200px 0px 0px;
    height: 100%;
    left: 0;
    margin-left: 5%;
    bottom: -15px;
    align-items: center;
    flex-direction: column;
    z-index: -1;
}
.privacy_policy-content p {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    text-align: justify;
    color: #000000;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
}
.privacy_policy-content {
    padding: 100px 0;
}
.section-1-left.error-page::before {
    content: '';
    display: none;
}
