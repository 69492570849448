.footer {
 position: relative;
 z-index: 20;
}
.footer-top ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}
.footer-top h4 {
    color: rgba(6, 0, 0, 0.73);
    font-family: Nunito;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
}
.footer-top ul li a {
    color: rgba(6, 0, 0, 0.73);
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.footer-top ul li {
    margin-bottom: 10px;
}
.line {
    height: 2px;
    background: #929292;
}
.footer-logo {
    text-align: center;
}
.footer-logo img {
    max-width: 325px;
    width: 100%;
    margin: 2rem auto;
}
.footer-logo p {
    color: #000;
    text-align: center;
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}
.queke-link ul {
    list-style: none;
    padding-left: 0;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
}
.social-icon a img {
    width: 27px;
    height: 27px;
}
.social-icon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
}
.social-icon a {
    margin-left: 20px;
}
.queke-link ul li a {
    color: #000;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}
.line-2 {
    height: 1px;
    background: #E2D4D4;
}
.footer-bottom {
    color: rgba(6, 0, 0, 0.73);
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
    z-index: 20;
}
._lightMode_ .footer {
    background: #383838;
}
._lightMode_ .footer-top h4 {
    color: rgba(216, 216, 216, 0.73);
}
._lightMode_ .footer-top ul li a {
    color: rgba(216, 216, 216, 0.73);
}
._lightMode_ .footer-logo .drack {
    display: none;
}
._darkMode_ .footer-logo .lite {
    display: none;
}
._darkMode_ .footer-logo .drack {
    display: block;
}
._lightMode_ .footer-logo p {
    color: rgba(216, 216, 216, 0.73);
}
._lightMode_ .queke-link ul li a {
    color: rgba(216, 216, 216, 0.73);
}
._lightMode_ .footer-bottom {
    color: rgba(216, 216, 216, 0.73);
}
.queke-link ul li {
    margin-right: 20px;
}
._darkMode_ .white {
    display: none;
}

@media (max-width: 576px) {
    /* For the quick links section on mobile */
    .queke-link ul {
      flex-direction: column; /* Stack links vertically */
      align-items: center;    /* Center them horizontally */
      justify-content: center;
    }
    
    .queke-link ul li {
      margin: 10px 0;         /* Add vertical spacing */
      margin-right: 0;        /* Remove the desktop right margin */
    }
    
    /* Optionally, adjust the font-size if needed */
    .queke-link ul li a {
      font-size: 16px;
    }
  }


 @media only screen and (min-width: 1001px) and (max-width: 1100px) {

    .social-icon {
        flex-wrap: nowrap;  /* Prevent icons from wrapping to a new line */
      }
      .social-icon a {
        margin-left: 10px;  /* Reduce margin to free up space */
      }

 }