.latest-courses {
}
.latest-courses-title {
  color: #000;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  z-index: 20;
  position: relative;
  margin-bottom: 5vh;
}
.courses-box {
  background: rgba(255, 255, 255, 0.3); /* Semi-transparent white background */
  border-radius: 13px;
  margin: 1rem 0.5rem 1rem 0;
  margin-bottom: 5vh;
  overflow: visible;
  z-index: 10;
  position: relative;
  width: 375px;
  height: 400px;
  backdrop-filter: blur(10px); /* Apply a blur effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Light border to enhance the glass effect */
}

.courses-box-content {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  justify-content: center; /* Center content vertically */
  gap: 5px; /* Slightly increase the gap between rows for better spacing */

  height: 100%; /* Ensure the content takes up full height of the box */
}

.courses-box-row {
  display: flex;
  justify-content: center; /* Center buttons horizontally */
  gap: 10px; /* Maintain space between the buttons */
  width: 100%; /* Ensure the row takes up the full width of the content area */
}

.courses-button-style {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the text and icon within the button */
  color: #000;
  font-family: Nunito;
  font-size: 14px; /* Reduce font size slightly */
  font-weight: 500;
}

.courses-button-style img {
  width: 20px; /* Reduce icon size slightly */
  height: 20px;
  margin-right: 5px;
}
.courses-box-top {
  position: relative;
  height: 150px; /* Set a fixed height for the top section to contain the image */
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-top-img-text {
  position: absolute;
  top: 13px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  left: 16px;
}
.box-top-img-text p {
  color: #fff;
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  margin-bottom: 0;
  margin-right: 16px;
}

.courses-box-title {
  padding: 10px 20px 10px 20px; /* Reduce top and bottom padding to bring the title closer to the buttons */
  margin-top: 7vh;
}
.courses-box-title h4 {
  color: #000;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.courses-box-content-button {
  border-radius: 20px;
  background: linear-gradient(
    90deg,
    rgba(235, 245, 255, 0.9) 0%,
    /* Very soft light blue */ rgba(250, 255, 255, 0.6) 100% /* Almost white */
  );
  padding: 8px 12px;
  margin: 10px 12px 10px 0;
  min-width: 153px;
  box-shadow: 0px 4px 15px rgba(0, 123, 255, 0.2); /* Subtle blue glow */
  transition: box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition for hover effects */
}

/* Optional hover effect for more interactivity */
.courses-box-content-button:hover {
  box-shadow: 0px 6px 20px rgba(0, 123, 255, 0.4); /* Slightly more noticeable blue glow on hover */
  transform: scale(1.05); /* Slightly scale the button on hover */
}

.img_box_shadow img {
  width: 250px; /* Set a fixed width for the image */
  height: 250px; /* Set a fixed height for the image */
  position: absolute; /* Position the image absolutely within its container */
  top: 50%; /* Center the image vertically */
  left: 50%; /* Center the image horizontally */
  transform: translate(
    -50%,
    -50%
  ); /* Adjust position to truly center the image */
}

.courses-button-style .d {
  display: block;
}
.courses-button-style img {
  display: none;
}

.disabled-link {
  color: rgba(0, 0, 0, 0.5); /* Lighter text color */
 
  filter: brightness(0.7); /* Make the entire button appear lighter */
}

/* Tooltip on hover */
.disabled-link:hover::after {
  content: "Please Subscribe!";
  position: absolute;
  background: rgba(0, 0, 0, 0.8); /* Dark background */
  color: #fff; /* White text */
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 5px;
  top: -30px; /* Position above the button */
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap; /* Prevent text wrapping */
  z-index: 10;
  opacity: 0;
  animation: fadeIn 0.3s forwards;
}

/* Tooltip fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


/* Mobile responsiveness for Courses component */
@media only screen and (max-width: 600px) {
  /* Ensure that sections marked as desktop-view show on mobile */

  /* Adjust section padding if needed */
  .latest-courses {
    padding: 20px 10px;
  }
  
  /* Make the courses box fluid */
  .courses-box {
    width: 100%;       /* Use full available width */
    height: auto;      /* Let content determine the height */
    margin: 1rem 0;    /* Adjust margins for spacing */
  }
  .courses-box-row {
    display: flex;
    gap: 10px; /* space between buttons */
  }
  
  
  /* Force all buttons to take equal width */
  .courses-box-content-button {
    flex: 1 1 45%;   /* Grow and shrink equally, starting at ~45% of the row */
    max-width: 45%;  /* Ensure they don’t exceed 45% of the container’s width */
    margin: 0 5px 10px;
    padding: 6px 8px;
  }
  
  /* Set the button text styles once to avoid duplicate/conflicting rules */
  .courses-button-style {
    font-size: 16px;  /* Increase text size for readability */
    padding: 6px 10px; /* Adjust padding as needed */
    text-align: center; /* Ensure centered text */
    
  }
  
  /* Optionally, adjust icon sizes */
  .courses-button-style img {
    width: 16px;
    height: 16px;
  }
  /* Ensure images scale down */
 
  
  /* Optionally, adjust font sizes and button padding */
  .latest-courses-title {
    font-size: 18px;
  }
  
  .courses-box-title h4 {
    font-size: 16px;
    font-weight: 700;
  }
  
  .courses-button-style {
    font-size: 12px;
    padding: 6px 10px;
  }
  
  .courses-button-style img {
    width: 16px;
    height: 16px;
  }

  .img_box_shadow img {
    width: 200px; /* Reduced from 250px */
    height: auto;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  /* Let each course card fill its container */
  .courses-box {
    width: 100%;               /* Remove the fixed 375px width */
    max-width: 375px;          /* Prevent it from growing too large */
    height: auto;              /* Allow height to adjust to content */
    margin: 1rem auto;         /* Center within its grid column */
  }
  
  /* Adjust the top section to auto height */
  .courses-box-top {
    height: auto;
  }
  
  /* Scale down the image within the top section */
  .img_box_shadow img {
    width: 220px;              /* Reduced from 250px for tablets */
    height: auto;
  }
  
  /* Tweak the title font size */
  .courses-box-title h4 {
    font-size: 18px;
    font-weight: 400;
  }
  
  /* Adjust the button styles within the card */
  .courses-button-style {
    font-size: 12px;           /* Slightly smaller text */
    padding: 4px 10px;         /* Adjust button padding */
  }
  
  /* Optional: If you want to reduce the gap between buttons */
  .courses-box-row {
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
  }
  
  /* Adjust the button container so they don't exceed the card width */
  .courses-box-content-button {
    flex: 1 1 48%;            /* Allow each button to take roughly half the row */

    box-sizing: border-box;   /* Ensure padding is included in the width */
    margin: 5px 0;            /* Adjust vertical spacing */
    padding: 8px 10px;        /* Tweak padding as needed */
  }

  .img_box_shadow img {
    width: 150px;  /* Reduced from 250px */
    height: 150px; /* Reduced from 250px */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  /* Increase the vertical spacing between course boxes */
  .courses-box {
    /* You can either adjust the bottom margin or add extra margin */
    margin-bottom: 7vh; /* Increase vertical margin (was 5vh) */
  }

  .latest-courses-title{
    margin-bottom: 75px;
  }
}
