/* Increase overall section padding for extra space */
.change-password-section {
  padding: 20px 0;
}

/* Slightly reduce the heading font size and margin */
.change-password-heading {
  font-size: 1.3rem; /* Reduced from 1.5rem */
  margin-bottom: 16px;
  font-weight: 600;
  text-align: left;
}

/* Adjust alert spacing */
.alert {
  margin-bottom: 16px;
}

/* Make the input font a bit smaller and adjust padding */
#password-change-form .form-control {
  font-size: 0.875rem !important;
  padding: 0.375rem 0.75rem !important;
}

#password-change-form .form-control::placeholder {
  font-size: 1rem !important;
  /* You can also set the color, font-family, etc. */
}

/* Reduce the label font size */
.col-form-label,
.form-label {
  font-size: 1rem; /* Reduced from 1.25rem */
  font-weight: 500;
}

/* Optionally, if you want extra spacing between rows */
#password-change-form .row {
  margin-bottom: 0.75rem; /* Adjust this value if you need more/less spacing */
}

/* Style the Save Changes button with a smaller font and extra padding if desired */
.save-changes-btn {
  background-color: #5b9311 !important;
  border-color: #5b9311 !important;
  color: #fff;
  font-size: 0.875rem; /* Slightly smaller font for the button */
  padding: 0.5rem 1rem; /* Adjust padding for a balanced look */
}

.save-changes-btn:hover,
.save-changes-btn:focus {
  background-color: #4a7a0e !important;
  border-color: #4a7a0e !important;
}
