.search_bar_top_bottom {
  padding: 60px 0 0;
}

.search {
  position: relative;
  box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
}

.search input {
  height: 60px;
  text-indent: 25px;
  font-family: Nunito;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0em;
  border: 2px solid rgba(24, 168, 38, 0.5);
  text-align: left;
  color: #000000;
  border-radius: 10px;
}

.search input:focus {
  box-shadow: none;
  border: 2px solid #18a826;
}

.search .fa-search {
  position: absolute;
  top: 20px;
  left: 16px;
}

.search button {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 50px;
  width: 110px;
  background: #18a826;
  border: 1px solid #18a826;
}

.search button:hover {
  background: #18a826;
}
