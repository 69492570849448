/* =========================================================
   Overall Page Layout
   ========================================================= */
.success-page {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  margin-top: 80px;
  padding-top: 20px;
}

.success-background {
  position: relative;
  z-index: 20;
}

.items-container {
  margin-bottom: 1.5rem; /* space beneath the items list */
}

/* =========================================================
     Success Box
     ========================================================= */
.success-box {
  background-color: #fff;
  border-radius: 12px;
  min-height: 450px; /* or any desired height */
  padding: 2.75rem;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);

  /* Let’s use flex to make it horizontal for bigger screens */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; /* allows wrapping on smaller screens */
}

/* Left portion: icon + "Payment Success!" + big price */
.success-box-left {
  min-width: 200px;
  margin-bottom: 1rem;
}

/* Right portion: the details list */
.success-box-right {
  flex: 1;
  min-width: 250px;
}

/* Optional vertical divider for desktop screens */
.success-divider {
  width: 1px;
  height: 120px;
  background-color: #eaeaea;
  margin: 0 2rem;
}

/* =========================================================
     Text, Icons, Etc.
     ========================================================= */
.success-icon {
  width: 80px;
  height: auto;
  margin-bottom: 1rem;
}

.success-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #474747;
  margin-bottom: 0.5rem;
}

.success-amount {
  font-size: 2rem;
  font-weight: 700;
  color: #121212;
  margin-bottom: 0.5rem;
}

/* Payment detail list */
.list-payment-box p {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
}

.list-payment-box .label {
  color: #707070;
  font-weight: 600;
  font-size: 1rem;
}

.list-payment-box .main-content {
  color: #333;
  font-size: 1rem;
}

/* Illustration on the left or right side */
.success-illustration {
  max-width: 100%;
  height: auto;
}

/* =========================================================
     Responsive Tweaks
     ========================================================= */
@media only screen and (max-width: 767px) {

  .success-page {

    margin-top: 0px;
    padding-top: 0px;
  }
  .divider {
    display: none;
  }

  .success-box {
    flex-direction: column;
    text-align: center;
  }

  .success-box-left,
  .success-box-right {
    margin-bottom: 1rem;
  }
}

@media (max-width: 850px) {

  .success-page {
 
    margin-top: 0px;
    padding-top: 0px;
  }

  .success-box {
    flex-direction: column; /* Stack items vertically */
    justify-content: center; /* Center them vertically */
    align-items: center; /* Center them horizontally */
  }

  .success-box-left,
  .success-box-right {
    width: 100%; /* Full width to center content */
    margin: 0 auto; /* Center the container */
    text-align: center; /* Center text within each section */
  }

  /* Hide the vertical divider on smaller screens */
  .success-divider {
    display: none !important; 
  }

  /* Optional: Adjust spacing if needed */
  .success-box-left {
    margin-bottom: 1.5rem;
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1100px) {

  .success-box {
    flex-direction: column; /* Stack items vertically */
    justify-content: center; /* Center them vertically */
    align-items: center; /* Center them horizontally */
  }

  .success-box-left,
  .success-box-right {
    width: 100%; /* Full width to center content */
    margin: 0 auto; /* Center the container */
    text-align: center; /* Center text within each section */
  }

  /* Hide the vertical divider on smaller screens */
  .success-divider {
    display: none !important; 
  }

  /* Optional: Adjust spacing if needed */
  .success-box-left {
    margin-bottom: 1.5rem;
  }

}