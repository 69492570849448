.quiz-section {
  background: linear-gradient(180deg, #deeff6 0%, #fbfff5 100%);
  margin-top: 80px;
  padding-top: 20px;
}
.top-header-quiz {
}
.quiz-box {
  position: relative; /* Set relative positioning to contain the absolute child */
  border-radius: 13px;
  background: linear-gradient(180deg, #dff0f7 0%, #fff 100%);
  padding: 30px;
  display: block;
  z-index: 20;
  opacity: 0.9;
  width: 400px;
  height: 115px;
}

.right-arror-key {
  position: absolute; /* Position absolutely within the relative parent */
  bottom: 10px; /* Position 10px from the bottom */
  right: 10px;  /* Position 10px from the right */
}
.quiz-box h4 {
  color: #000;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
/* dark */
._lightMode_ .top-header-quiz {
  background: #0000;
}
._lightMode_ .quiz-box {
  background: #494949;
}
._lightMode_ .quiz-box h4 {
  color: #fff;
}
.quiz-filter-top-heading {
  border-radius: 13px;
  background: linear-gradient(180deg, #f6f6de 0%, #fbfff5 100%);
  padding: 30px 20px;
}
.quiz-main-box-style {
  border-radius: 5px;
  width: 100%;
  background: linear-gradient(180deg, #f6f6de 0%, #fbfff5 100%);
  padding: 20px;
  margin-bottom: 2rem;
}
._lightMode_ .quiz-main-box-style {
  background: #494949;
}
._lightMode_ .quiz-filter-top-heading {
  background: #494949;
}
.quiz_top_header_top {
  text-align: center;
  position: relative;
  z-index: 20;
}

.quiz_top_header h2 {
  color: #000;
  font-family: Nunito;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
}

.quiz_top_header p,
.quiz_top_header h2 {
  margin: 0 auto;
  padding: 0;
}

.quiz_top_header p {
  color: #000;
  font-family: Nunito;
  font-size: 26px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.2;
  text-transform: uppercase;
  margin-left: 0;
  margin-bottom: 10px; /* Add your desired margin value here */
}
.quiz_row_page {
  align-items: center;
}
.quiz_question_top p {
  color: #000;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: relative;
  z-index: 20;
}
.quiz_main_content_box img {
  width: 24px;
  margin-right: 10px;
}
.quiz_main_content_box {
  margin: 20px 0;
  color: #363636;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  z-index: 20;
}

.quiz_main_content {
  position: relative;
  z-index: 20;
}
.quiz_main_bottom_content p {
  color: #000;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: relative;
  z-index: 20;
}
.quiz_main_bottom_content p span {
  color: #5b9311;
}
.quiz_bottom_button .quiz-box {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background: #5b9311;
  color: #fff;
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
  border-color: #5b9311;
}
.quiz_bottom_button {
  max-width: 367px;
  width: 100%;
  margin-bottom: 50px;
}
.display-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.quiz-page-style {
  align-items: center;
}
.quiz-page-style p {
  margin-bottom: 0;
}
.quiz_bottom_right {
  display: flex;
  flex-direction: column; /* Stack the items vertically */
  align-items: flex-start; /* Align items to the left */
  position: relative;
  z-index: 20;
}

.quiz_bottom_right p {
  margin: 0 0 5px 0; /* Add some space below the text */
  font-size: 16px;
  margin-left: 30px;
}

.quiz_bottom_right button {
  color: #5b9311;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: underline;
  background: transparent;
  padding: 0;
  border: none; /* Remove button background and border */
}

.quiz_bottom_right button:hover {
  background: #0000;
  color: #5b9311;
}
.Keyboard-Shortcuts .modal-header {
  border: none;
  padding-bottom: 0;
}
.Keyboard-Shortcuts .modal-body {
  max-width: 600px;
  width: 100%;
  margin: auto;
}
.Keyboard-Shortcuts .modal-body h4 {
  color: #000;
  font-family: Nunito;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Keyboard-Shortcuts-box-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.Keyboard-Shortcuts-box-content-button {
  display: flex;
  align-items: center;
}
.Keyboard-Shortcuts-box-content p {
  color: #000;
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Keyboard-Shortcuts-box-content-button p {
  border-radius: 5px;
  background: #d9d9d9;
  padding: 1px 5px;
  margin-left: 10px;
}
.quiz_top_header_button {
  display: flex;
}
.quiz_top_header_button p {
  color: #000;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;

  margin: 0px 5px;
  background: #d9d9d9;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 0px;
  

}

.form-check-input:checked {
  background-color: #5b9311;
  border-color: #5b9311;
  border-radius: 6px;
  padding: 11px;
  border: 1px solid #5b9311;
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.25) inset;
}
.B .form-check-input:checked {
  background-color: #952e2e;
  border-color: #952e2e;
  border-radius: 6px;
  padding: 11px;
  border: 1px solid #952e2e;
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.25) inset;
}
.form-check-input {
  border-radius: 6px !important;
  border: 1px solid #999696;
  background-color: #d9d9d9;
  height: 30px;
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.25) inset;
  width: 35px;
  margin: 0;
}
.form-check-input:checked[type="radio"] {
  background-size: 60% 60%;
  background-position: center;
  background-repeat: no-repeat;
  --bs-form-check-bg-image: url("../img/icon/chack_icon.png");
}

.B .form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: url("../img/icon/wrong.png");
}
.chack_button_style_quiz {
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin: 25px 0;
  border-radius: 5px;
  border: 1px solid #8f8888;
  padding: 5px 5px 5px 10px;
}
.chack_button_style_quiz label {
  color: #000;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 85%;
  text-align: start;
}
.chack_button_style_quiz label p {
  margin-bottom: 0;
}
/* drack */
._lightMode_ .quiz_question_top p {
  color: #fff;
}
._lightMode_ .quiz_main_content_box {
  color: #fff;
}
._lightMode_ .quiz_main_bottom_content p {
  color: #fff;
}
._lightMode_ .quiz_bottom_right p {
  color: #818181;
}
/* new css */
.see-why-popup {
  display: flex;
  align-items: center;
  color: #5b9311;
  position: relative;
  top: 25px;
  margin-top: -50px;
}
.wrong-answer {
  color: #5b9311;
}
.see-why-popup img {
  max-width: 150px;
  width: 100%;
}
.red {
  color: #952e2e;
}
.red-button {
  background: #952e2e !important;
  border-color: #952e2e;
}
.red-button:hover {
  border-color: #952e2e !important;
}

.green-button {
  background: #5b9311 !important;
  border-color: #5b9311
}
.green-button:hover {
  border-color: #5b9311 !important;
}
.result-chack {
  width: 46px;
  height: 38px;
  border-radius: 6px;
  border: 1px solid #5b9311;
  background: #5b9311;
  display: flex;
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.25) inset;
  align-items: center;
  justify-content: center;
}
.result-chack img {
  width: 24px;
  height: auto;
}
.result-box {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 1rem 0;
  border-radius: 13px;
  border: 1px solid #ffffff;
  padding: 4px 20px;
  position: relative;
  z-index: 20;
}
.result-box p {
  margin-bottom: 0;
}
.result-box p {
  color: #000;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.result-chack.red {
  background: #952e2e;
  border: 1px solid #952e2e;
}
.result-right-box p {
  color: #818181;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: relative;
  z-index: 20;
}
.result-right-box {
  max-width: 400px;
  margin: auto;
  width: 100%;
}
.result-show {
  margin-bottom: 2rem;
}
.result-show-bottom img {
  width: 25px;
  margin-left: 10px;
}
.result-show-bottom {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.result-show-bottom p {
  color: #5b9311;
  font-family: Nunito;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.box-content-center {
  align-items: center;
  margin-bottom: 2.25rem;
}
._lightMode_ .result-box p {
  color: #fff;
}
.quizquctionnumber {
  background-color: #5b9311 !important;
  color: #fff !important;
}
.quiz-box-start {
  background: #becfa7;
  border-color: #becfa7;
}

.quiz-box-start:hover {
  background: #becfa7;
}

.quiz-box-start:focus-visible {
  color: #fff;
  background-color: #becfa7;
  border-color: #becfa7;
  outline: 0;
  box-shadow: none;
}
.quiz-box-start-check:checked + .quiz-box-start,
.quiz-box-start.active,
.quiz-box-start.show,
.quiz-box-start:first-child:active,
:not(.quiz-box-start-check) + .quiz-box-start:active {
  color: #fff;
  background-color: #becfa7;
  border-color: #becfa7;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: #fff;
  background-color: #5b9311 !important;
  border-color: #5b9311 !important;
}
.heand-icon {
  cursor: pointer;
}
.quiz_bottom_button:empty {
  display: none;
}
.quiz-box.red-button.btn.btn-primary {
  padding: 0px;
}
.quiz-box.red-button.btn.btn-primary {
  max-width: 300px;
  height: 50px;
  border-color: #000000 !important;
}

.quiz-box.green-button.btn.btn-primary {
  padding: 0px;
}
.quiz-box.green-button.btn.btn-primary {
  max-width: 300px;
  height: 50px;
  border-color: #000000 !important;
}
.button-width_300 {
  max-width: 300px;
}

.highlight-correct {
  border: 2px solid #5b9311; /* Green border for the correct answer */
}

@media (max-width: 768px) {
  .quiz-section {
    margin-top: 0px;
    padding-top: 0px;
  }

  .quiz-box {
    width: 90%;           /* Use a relative width so it adapts to the viewport */
    height: auto;         /* Let the height adjust based on content */
    padding: 20px;        /* Reduce padding for smaller screens */
    margin: 10px auto;    /* Center the box and add vertical spacing */
  }
  
  .quiz-box h4 {
    font-size: 18px;      /* Scale down the heading font-size */
    text-align: center;   /* Center the heading text */
    margin: 0;            /* Remove any extra margin if needed */
  }
  
  .right-arror-key {
    bottom: 5px;          /* Adjust arrow position for a tighter layout */
    right: 5px;
  }

  .quiz_top_header_top{
    margin-top: 30px;
  }

  .unit-page{
    min-height: 75vh;
  }

}

