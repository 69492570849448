/****************************************
 * 1) GLOBAL STYLES & RESETS
 ****************************************/

/* Example font reset, box-sizing, etc. */
/* (Optional) Use your own reset or frameworks like Normalize */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Nunito", sans-serif;
  background-color: #fff;
  color: #333;
}

/****************************************
 * 2) LAYOUT & CONTAINERS
 ****************************************/

/* Parent container for entire unit/page */
.unit-page {
  min-height: 100vh;
  position: relative;
}

.questionbank-unit{
  margin-top: 80px;
  padding-top: 20px;
}

/* Sticky column for side elements or filters */
.sticky-column {
  position: sticky;
  top: 100px; /* Adjust based on your navbar/header height */
  height: fit-content;
  z-index: 70;
}

/* Main questions container */
.questions-container {
  position: relative;
  z-index: 20;
  margin-bottom: 10vh;
  padding: 20px;
  border-radius: 20px;
  background-color: rgba(223, 240, 247, 0.6);
  width: 100%; /* Takes full width of parent */
  height: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/****************************************
 * 3) TITLES & HEADINGS
 ****************************************/

.unit-title {
  font-size: 1.25em;
  font-weight: bold;
  margin-bottom: 20px; /* Space below the title */
  margin-left: 4vw;
}

.h3-title {
  font-size: 2.15em;
}

.title-container {
  display: flex; /* Layout for a title + icon */
}

.status-icon {
  margin-left: 15px;
  width: 45px;
  height: 45px;
}

/****************************************
 * 4) QUESTION LIST & SCROLL
 ****************************************/

/* Horizontal scroll container for question list */
.question-list-scroll {
  display: flex;         /* Align child items in a row */
  flex-wrap: nowrap;     /* Prevent items from wrapping */
  overflow-x: auto;      /* Horizontal scrolling */
  overflow-y: auto;      /* Vertical scrollbar (if needed) */
  position: sticky;      
  top: 100px;            /* Adjust for your header height */
  z-index: 70;           /* Appear above other content */
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  scrollbar-width: thin;             /* For Firefox */
  scrollbar-color: #c0c0c0 #e0e0e0;   /* For Firefox */
  width: 100%;
}

/* Each question item inside the scroll */
.question-list-item {
  flex: 0 0 auto;
  margin-right: 8px;
  margin-bottom: 2px;
  padding: 4px 10px;
  cursor: pointer;
  font-size: 18px;
  transition: color 0.3s;
  width: auto;
  color: black;
  background-color: transparent;
  border: none;
  font-family: "Nunito";
  text-decoration: underline;
  font-weight: 700;
}

.question-list-item.active {
  color: #0083AF; /* Active state color */
}

.question-list-item:hover {
  /* Optionally add a hover style */
}

/****************************************
 * 5) BUTTONS (TOPIC, DIFFICULTY, ETC.)
 ****************************************/

/* TOPIC BUTTON */
.topic-button {
  width: 100%;
  max-width: 350px; /* Set a fixed max width */
  min-height: 75px; /* Fixed height */
  background-color: rgba(223, 240, 247, 0.7); 
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  color: #333;
  font-weight: 600;
  text-align: left;
  transition: background-color 0.3s ease;
  margin: 10px auto 20px auto;
  display: flex; 
  align-items: center;
  justify-content: space-between;
}

/* Text inside topic button */
.topic-button-text {
  flex-grow: 1;
  font-size: 20px;
}

/* Icon inside topic button */
.topic-button-icon {
  font-size: 20px;
}

/* Hover & selected states */
.topic-button:hover {
  background-color: rgba(224, 224, 224, 0.7);
  color: #000;
}

.topic-button.selected {
  background-color: rgba(215, 255, 192, 0.7);
  color: #000;
}

.topic-button.selected .topic-button-icon {
  color: #000;
}

/* DIFFICULTY FILTERS */
.difficulty-filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 30px; /* Space between filter buttons */
}

.difficulty-filters .btn {
  flex-grow: 0;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  color: #333;
  font-weight: bold;
  padding: 8px 8px;
  text-align: center;
  border-radius: 20px;
  transition: background-color 0.3s ease, 
              color 0.3s ease, 
              box-shadow 0.3s ease;
  position: relative;
  z-index: 20;
  width: 200px;
  height: auto;
}

.difficulty-filters .btn.active {
  background-color: #007bff;
  color: white;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.8); 
}

.difficulty-filters .btn:hover {
  background-color: #e0e0e0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}

.difficulty-filters .btn img {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

.difficulty-filters .btn:last-child {
  margin-right: 0;
}

/* CUSTOM BUTTON CONTAINER (for topic buttons, etc.) */
.custom-button-container {
  display: flex;
  flex-direction: column;
  gap: 0px;
  max-width: 300px;
  margin-left: 2vw;
  margin-right: 5vw;
}

/****************************************
 * 6) ADDITIONAL BUTTONS (FORMULA, MARKSCHEME, ETC.)
 ****************************************/

/* FORMULA BUTTON */
.formulaButton {
  font-size: 20px;
  font-family: "Nunito", sans-serif;
  color: black;
  position: absolute;
  top: 2vh;
  right: 2vw;
  padding: 5px 10px;
  background-color: rgba(63, 168, 26, 0.35);
  border-radius: 12px;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: 600;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  transition: background-color 0.3s ease;
}

.formulaButton:hover {
  background-color: rgba(63, 168, 26, 0.5);
  color: black;
}

.formulaIcon {
  width: 70px;
  height: auto;
}

.formulatext {
  margin-left: -5px; /* Adjust as needed */
}

/* MARKSCHEME BUTTON */
.markscheme-button {
  font-size: 20px;
  font-family: "Nunito", sans-serif;
  color: black;
  padding: 10px 20px;
  background-color: rgba(63, 168, 26, 0.4);
  border-radius: 12px;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: 600;
  border: none;
  transition: background-color 0.3s ease, 
              box-shadow 0.3s ease, 
              color 0.3s ease;
  box-shadow: 0 0 10px rgba(112, 112, 112, 0.7);
  margin-right: 20px;
}

.markscheme-button:hover {
  background-color: rgba(63, 168, 26, 0.6);
  color: #000;
  box-shadow: 0 0 15px rgba(0, 131, 175, 0.9);
}

.markscheme-icon {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

/* VIDEO ANSWER BUTTON */
.videoanswer-button {
  font-size: 20px;
  font-family: "Nunito", sans-serif;
  color: black;
  padding: 10px 20px;
  background-color: rgba(26, 168, 134, 0.35);
  border-radius: 12px;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: 600;
  border: none;
  transition: background-color 0.3s ease, 
              box-shadow 0.3s ease, 
              color 0.3s ease;
  box-shadow: 0 0 10px rgba(112, 112, 112, 0.7);
  margin-right: 20px;
}

.videoanswer-button:hover {
  background-color: rgba(26, 168, 134, 0.6);
  color: #000;
  box-shadow: 0 0 15px rgba(26, 168, 134, 0.9);
}

.videoanswer-icon {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

/* SIMILAR QUESTIONS BUTTON */
.similarquestion-button {
  font-size: 20px;
  font-family: "Nunito", sans-serif;
  color: black;
  padding: 10px 20px;
  background-color: rgba(26, 100, 168, 0.35);
  border-radius: 12px;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: 600;
  border: none;
  transition: background-color 0.3s ease,
              box-shadow 0.3s ease,
              color 0.3s ease;
  box-shadow: 0 0 10px rgba(112, 112, 112, 0.7);
}

.similarquestion-button:hover {
  background-color: rgba(26, 100, 168, 0.6);
  color: #000;
  box-shadow: 0 0 15px rgba(26, 100, 168, 0.9);
}

.similarquestion-icon {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

/****************************************
 * 7) QUESTION PHOTO
 ****************************************/

.question-photo {
  background-color: white;
  width: 100%;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 3vh;
  margin-top: 3vh;
}

/****************************************
 * 8) ADDITIONAL BUTTONS WRAPPER
 ****************************************/

.additional-buttons {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.additional-button {
  font-size: 16px;
  font-family: "Nunito", sans-serif;
  color: black;
  padding: 10px 20px;
  background-color: rgba(63, 168, 26, 0.35);
  border-radius: 5px;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-right: 50px;
  width: 20%;
  text-align: center;
  border: none;
}

.additional-button img {
  margin-right: 8px;
  width: 24px;
  height: 24px;
}

/****************************************
 * 9) FULLSCREEN BUTTON
 ****************************************/

 .fullscreen-button {
  /* Remove these lines: */
  /* position: absolute;
  bottom: 10px;
  right: 10px; */
  margin-left: auto; /* push it to the right in the flex row */
  background: none;
  border: none;
  color: #000;
  font-size: 20px;
  cursor: pointer;
}


.fullscreen-button:hover {
  color: #000000;
}

.questions-container:fullscreen {
  width: 100vw;
  height: 100vh;
  padding: 20px;
  background: #fff;
  overflow-y: auto;
}

/****************************************
 * 10) BASIC RESPONSIVE MEDIA QUERIES
 ****************************************/

/* Tablet Portrait (max-width: 768px) */
@media screen and (max-width: 768px) {
  .topic-button {
    font-size: 18px;
    padding: 8px 16px;
    min-height: 60px;
    max-width: 100%;
  }
  
  .topic-button-text {
    font-size: 18px;
  }

  .difficulty-filters {
    flex-direction: column;
    gap: 15px; /* Spacing between filter buttons */
    align-items: flex-start;
  }

  .difficulty-filters .btn {
    width: 100%;
  }

  .unit-title {
    margin-left: 2vw;
    font-size: 1.1em;
  }

  /* Adjust additional buttons container */
  .additional-buttons {
    flex-direction: column;
    gap: 10px;
  }

  .additional-button {
    width: 100%;
    margin-right: 0;
  }

  /* Possibly reduce the size of the .formulaButton, etc. */
  .formulaButton {
    top: 2vh;
    right: 2vw;
    font-size: 16px;
  }

  /* Title container stack vertically */
  .title-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .questionbank-unit{
    margin-top: 0px;
    padding-top: 0px;
  }

  .sticky-column {
    position: static;
    top: auto;
    margin-bottom: 75px; /* Optional: Adds space below the sidebar */
  }

  /* Remove sticky behavior for the question list scroll */
  .question-list-scroll {
    position: static;
    top: auto;
    margin-bottom: 20px; /* Optional: Adds space after the question list */
  }
  
    /* ---------------------------
     Topic Buttons
  ----------------------------- */
  .topic-button {
    width: 100%;              /* Ensure full width */
    font-size: 16px;          /* Slightly smaller font for mobile */
    padding: 12px 16px;       /* Increase tap area */
    margin: 8px 0;            /* Vertical spacing between buttons */
    border-radius: 8px;       /* Optional: a bit more rounded */
  }
  .topic-button-text {
    font-size: 16px;
  }
  
  /* ---------------------------
     Difficulty Filter Buttons
  ----------------------------- */
  .difficulty-filters {
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }
  .difficulty-filters .btn {
    width: 100%;              /* Full width for easier tapping */
    font-size: 16px;
    padding: 10px;
    text-align: center;       /* Center the text */
    border-radius: 8px;       /* Optional: soften the corners */
  }
  
  /* ---------------------------
     Additional Action Buttons 
     (Markscheme, Video Answer, Similar Question)
  ----------------------------- */
  .markscheme-button,
  .videoanswer-button,
  .similarquestion-button {
    width: 100%;              /* Full width buttons */
    margin: 5px 0;            /* Vertical spacing between buttons */
    padding: 10px;            /* Increase tap area */
    font-size: 16px;
    justify-content: center;  /* Center content inside */
    border-radius: 8px;       /* Consistent rounding */
  }
  
  /* ---------------------------
     Formula Button Adjustments
  ----------------------------- */
  /* Since the formula button is positioned absolutely, you might want to adjust its placement or size,
     but here we just ensure its font and padding are mobile-friendly */
  .formulaButton {
    top: 2vh;
    right: 2vw;
    font-size: 16px;
    padding: 5px 8px;
  }
  
  /* ---------------------------
     Additional Button Container
  ----------------------------- */
  .difficulty-filters {
    flex-direction: row;            /* Arrange buttons in a row */
    justify-content: center;        /* Center them horizontally */
    gap: 10px;                      /* Space between circles */
    align-items: center;
  }
  
  .difficulty-filters .btn {
    min-width: 80px;                    /* Fixed width for a circle */
    height: 60px;                   /* Fixed height equal to width */
    padding: 0;                     /* Remove extra padding */
    border-radius: 50%;             /* Make it perfectly circular */
    display: flex;                  /* Use flex for centering */
    align-items: center;            /* Center content vertically */
    justify-content: center;        /* Center content horizontally */
    overflow: hidden;               /* Hide any overflow */
    background-color: #f0f0f0;        /* Maintain your background color */
    border: 1px solid #ccc;         /* Optional: add a border */
  }
  
  /* Hide any text inside the button */
  .difficulty-filters .btn span,
  .difficulty-filters .btn #text {
    display: none;
  }
  
  /* Adjust the image inside the button */
  .difficulty-filters .btn img {
    display: none;                  /* Hide the image */
  }

  .question-photo, 
  .question-photo p {
    font-size: 1.2rem; /* Adjust the value as needed */
    line-height: 1.4;
  }



  /* Also scale down the icons inside these buttons */
  .title-container {
    flex-direction: row;
    align-items: center;
  }

  .status-icon{
    margin-bottom: 5px;
  }
  
}

/* Mobile (max-width: 480px) */
@media screen and (max-width: 480px) {
  .topic-button {
    font-size: 16px;
    padding: 6px 10px;
    min-height: 50px;
  }

  .topic-button-text {
    font-size: 16px;
  }

  .question-list-item {
    font-size: 16px;
  }

  .h3-title {
    font-size: 1.75em;
  }

  .status-icon {
    width: 35px;
    height: 35px;
  }

  .custom-button-container {
    margin-left: 2vw;
    margin-right: 2vw;
    max-width: 100%;
  }

  .formulaIcon {
    width: 50px;
  }
  
  .formulaButton {
    font-size: 14px;
    padding: 5px 8px;
  }

  .markscheme-button,
  .videoanswer-button,
  .similarquestion-button {
    font-size: 16px;
    padding: 8px 12px;
    margin-right: 10px;
  }
}


/* 
  Minimal replacements for Container, Row, Col
  that mimic Bootstrap behavior.
*/

.my-container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.my-row {
  display: flex;
  flex-wrap: wrap;

}

.my-col-sm-12,
.my-col-sm-11,
.my-col-sm-8,
.my-col-sm-4,
.my-col-sm-3 {
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; /* Default: full width on mobile */
  box-sizing: border-box;
}

/* "sm" breakpoints: up from 576px (example) */
@media (min-width: 576px) {
  .my-col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .my-col-sm-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }
  .my-col-sm-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }
  .my-col-sm-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
  .my-col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.qb-heading  {
 padding: 10px;
}




.qb-heading p {
  margin-left: 0px; /* Move to the left by 20px */
  padding-right: 20px; /* Optional: Add padding on the right to maintain balance */
  z-index: 20;
  position: relative;
  margin-bottom: 0px; /* Optional: Add space below the heading */
}

.qb-heading h2 {
  font-size: 2.5rem; /* Adjust the size as needed */
  font-weight: 700; /* 700 is usually considered bold */
  line-height: 1.2; /* Adjust line-height if needed */
  margin-bottom: 20px; /* Optional: Add space below the heading */
  color: #333; /* Optional: Darker color for better readability */
  margin-left: 10px;
}

@media (max-width: 850px) {
  /* Adjust the logo size */
  .questionbank-unit{
    margin-top: 0px;
    padding-top: 0px;
  }

  .markscheme-button,
  .videoanswer-button,
  .similarquestion-button {
    width: 200px;             /* Full width inside their container */
        /* Cap the width to keep them uniform */
    margin: 10px auto;       /* Center each button and add vertical spacing */
    font-size: 13px;         /* Adjust the font size for tablet screens */
    padding: 2px 2px;       /* Adjust padding for a balanced tap area */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .question-list-scroll {
    position: static;
    top: auto;
    margin-bottom: 20px; /* Optional: Adds space after the question list */
  }


}

@media only screen and (min-width: 1001px) and (max-width: 1100px) {
  /* Adjust the logo size */
  .questionbank-unit{
    margin-top: 80px;
    padding-top: 20px;
  }

  .markscheme-button,
  .videoanswer-button,
  .similarquestion-button {
    width: 250px;             /* Full width inside their container */
        /* Cap the width to keep them uniform */
    margin: 10px auto;       /* Center each button and add vertical spacing */
    font-size: 16px;         /* Adjust the font size for tablet screens */
    padding: 5px 5px;       /* Adjust padding for a balanced tap area */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
}
