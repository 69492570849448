/* Course name button */
.course-name-button {
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.3);
    font-family: Nunito, sans-serif;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    outline: none;
    padding: 12px 20px;
    margin: 0;
    text-align: left;
    width: 100%;
    border-radius: 8px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    transition: background 0.3s, transform 0.3s, color 0.3s;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .course-name-button:hover {
    background: rgba(255, 255, 255, 0.25);
    transform: translateY(-2px);
  }
  
  /* Arrow icon inside the button */
  .arrow-icon {
    font-size: 14px;
    transition: transform 0.3s, color 0.3s;
    color: inherit; /* Ensure the icon inherits the text color */
  }
  
  .arrow-icon.open {
    transform: rotate(90deg); /* Rotate arrow to point down when open */
  }
  
  /* Container for the additional buttons */
  .icon-img {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
  }
  
  /* Glass-like buttons */
  .glass-button {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    margin: 0;
    border-radius: 8px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: #000;
    text-decoration: none;
    border: none;
    transition: transform 0.3s;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
  }
  
  .glass-button:hover {
    transform: translateY(-1px);
  }
  
  /* Adjust images inside buttons */
  .glass-button img {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }
  
  /* List styling */
  .cur-box-content {
    list-style: none;
    padding-left: 0;
    margin-top: 20px;
  }
  
  .cur-box-content-button {
    margin-bottom: 20px;
  }
  
  /* Text color classes (if using CSS classes instead of inline styles) */
  .text-purple {
    color: #6708B1;
  }
  
  .text-green {
    color: #275285;
  }
  
  .text-blue {
    color: #497B22;
  }
  
  .text-red {
    color: #970000;
  }
  