/* Make the modal a bit wider */
.custom-modal .modal-dialog {
    max-width: 800px; /* Wider than default */
    margin: 2rem auto; /* Add space around the modal */
  }
  
  /* Dim the entire background behind the modal */
  .modal-backdrop.show {
    background-color: rgba(0, 0, 0, 0.8) !important; /* Dark backdrop */
  }
  
  /* Subtle glass effect on the modal content */
  .custom-modal .modal-content {
    background: rgba(255, 255, 255, 0.9); /* More opaque; not very see-through */
    backdrop-filter: blur(2px);           /* Minimal blur */
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    color: #000; /* Switch text to dark if your background is light */
    overflow: hidden; 
    min-height: 300px;
  }
  
  /* Remove Bootstrap's default borders in header & footer */
  .custom-modal .modal-header,
  .custom-modal .modal-footer {
    border: none;
    background: transparent;
  }
  
  /* Logo styling (center it and give space) */
  .custom-modal .modal-header {
    display: flex;
    justify-content: center; 
    align-items: center;
    position: relative; 
    border: none;
    background: transparent;
  }

  .custom-modal .modal-header .btn-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    outline: none;
  }
  
  .custom-modal .modal-logo {
    max-width: 280px;
    height: auto;
    margin-bottom: 0; /* No extra margin needed here */
    margin-right: 10px;
  }
  
  /* Body: center text, bigger font, add padding */
  .custom-modal .modal-body {
    text-align: center;
    font-size: 20px;
    padding: 2rem;
  }
  
  /* We moved the title to the body as an <h2> */
  .modal-title-body {
    margin-bottom: 1rem; /* space below the heading */
  }
  
  /* Center footer elements */
  .custom-modal .modal-footer {
    justify-content: center;
    padding-bottom: 2rem;
  }
  
  .custom-ok-button {
    background-color: #5b9311; /* Example brand color */
    border: none;
    color: #fff; /* White text */
    font-size: 1.1rem;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  /* Hover/focus states for a better UX */
  .custom-ok-button:hover,
  .custom-ok-button:focus {
    background-color: #4c7911 !important; /* Darker shade */
    outline: none;
  }