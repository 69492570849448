#VisaChipCardVideo {
  width: 100%;
}
.pfd_popup_main {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  margin: auto;
  height: 100%;
  background: #000000bf;
}
.pfd_colse_icon {
  position: absolute;
  right: 0;
  top: auto;
  cursor: poNunito;
  width: 25px;
  cursor: poNunito;
  height: 25px;
  background: #000;
  color: #fff;
  display: flex;
  font-size: 20px;
  align-items: center;
  border-radius: 0px 0px 0px 14px;
  justify-content: center;
  padding-bottom: 7px;
  z-index: 2;
}
.btn-close:hover {
  color: #fff;
  text-decoration: none;
  opacity: 0.75;
}
.main-pfd-popup {
  position: relative;
  margin: auto;
  width: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  height: 80%;
  background: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
}
.main-pfd-popup img {
  max-width: 730px;
  width: 100%;
  padding: 20px;
}
.model-body {
  overflow: auto;
  height: 100%;
  text-align: center;
  background: #fff;
}
.object_pdf {
  width: 1200px;
  height: 1000px;
}
@media only screen and (max-width: 767px) {
  .object_pdf {
    max-width: 767px;
    width: 100%;
  }
  .main-pfd-popup {
    width: auto;
    height: auto;
  }
}
