.squares-art {
  position: absolute;
  overflow: visible;
}

/* 
  Initial phase:
  - No rotation (rotate(0deg)).
  - Smooth transition (1.5s) for a slow, graceful effect.
*/
.squares-art .square {
  transform: rotate(115deg);
  transition: transform 1.5s ease-in-out;
}

/* 
  Hover state:
  - Each square rotates to 106deg + (index * 10deg),
    ensuring a 10-degree gap between squares.
*/
.squares-art:hover .square {
  transform: rotate(calc(-106deg + var(--index) * 10deg));
}


@media (max-width: 768px) {
  .squares-art {
    display: none;
  }
}