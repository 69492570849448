#VisaChipCardVideo {
    width: 100%;
}
.photo_popup_main {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    margin: auto;
    height: 100%;
    background: #000000bf;
    
}
.photo_colse_icon {
    position: absolute;
    right: 0;
    top: auto;
    cursor: poNunito;
    width: 25px;
    cursor: poNunito;
    height: 25px;
    background: #000;
    color: #fff;
    display: flex;
    font-size: 20px;
    align-items: center;
    border-radius: 0px 0px 0px 14px;
    justify-content: center;
    padding-bottom: 7px;
    z-index: 2;
}
.btn-close:hover {
    color: #fff;
    text-decoration: none;
    opacity: .75;
}
.main-photo-popup_photo {
    position: relative;
    /* margin: auto; */
    margin-top: auto;
    width: auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    /* height: 90%; */
    background: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    top: 10%;
    margin-bottom: 10%;
}
/* .main-photo-popup img {
    max-width: 730px;
    width: 100%;
    padding: 20px;
} */
.main-photo-popup_photo img {
    max-width: 1124px !important;
    width: 100% !important;
    padding: 20px;
    height: auto !important;
}
.model-body_photo{
    overflow: auto;
    height: 100%;
    text-align: center;
    background: #fff;
    border-radius: 8px;
}
/*  */
.model-body_photo p {
    margin-bottom: 0;
}
@media only screen and (max-width: 767px) { 
    .main-photo-popup_photo {
        width: 90%;
        height: auto;
        margin: 0 !important;
    } 
    .main-photo-popup_photo img {
        height: auto !important;
    }
}
.popup_img img {
    width: 100% !important;
    height: 100% !important;
}
