/* Wrap the entire section in a relative container so 
   absolutely positioned images stay behind content */
.contactus-page {
  position: relative;   /* So child images can be absolutely placed */
  min-height: 100vh;    /* Ensure full height if desired */
  padding: 60px 0;      /* Vertical spacing */
  overflow: hidden;     /* Hide any overflow from large BG images */
  font-family: 'Nunito', sans-serif;
  margin-top: 80px;
  padding-top: 40px;
}

/* Background images placed absolutely, behind content */
.splatter-image-middle-contact {
  position: absolute;
  top: 0;
  left: 0;
  width: 99vw;
  height: 99vh;
  object-fit: cover;
  z-index: 0;          /* behind the form */
  opacity: 0.1;        /* lower so it’s subtle */
  pointer-events: none;
}

/* If you want multiple images: position them differently */
.splatter-image-right1-contact {
  position: absolute;
  top: 0;
  right: 0;
  width: 65vw;
  z-index: 0;
  opacity: 0.5;
  pointer-events: none;
}

.splatter-image-right2-contact {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 99vw;
  z-index: 0;
  opacity: 0.3;
  pointer-events: none;
}

/* Ensure the form stands out on top of the background */
.contactus-form-wrapper {
  position: relative;
  z-index: 2; /* Above background images */
  background: rgba(255, 255, 255, 0.85); /* A white “card” w slight transparency */
  backdrop-filter: blur(5px); /* If you want a frosted-glass effect (optional) */
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;
}

/* Headings */
.contactus-form-wrapper h2 {
  color: #2F2F2F;
  font-family: 'Nunito', sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  line-height: 1.2;
  text-align: center;
}

.contactus-form-wrapper h2 span {
  color: #5B9311;
}

/* Better input styling */
.content-page-from .form-control {
  border-radius: 6px;
  border: 1px solid #ccc;
  background-color: #fff;
  font-size: 16px;
  margin-bottom: 20px;
  padding: 10px;
  transition: border-color 0.2s;
}

.content-page-from .form-control:focus {
  outline: none;
  border-color: #5B9311; /* highlight on focus */
  box-shadow: 0 0 0 2px rgba(91, 147, 17, 0.2);
}

/* Button styling */
.content-page-from .btn-primary {
  background: #5B9311;
  border: none;
  font-weight: 600;
  transition: background-color 0.2s ease;
  padding: 12px;
  font-size: 16px;
}
.content-page-from .btn-primary:hover {
  background-color: #4a7810;
}

/* Checkbox styling */
.my-checkbox .form-check {
  display: flex;
  align-items: center;
  gap: 8px; 
  margin-bottom: 20px; 
}
.my-checkbox .form-check-input {
  width: 18px;
  height: 18px;
  margin: 0;
  cursor: pointer;
}
.my-checkbox .form-check-label {
  font-size: 16px; 
  margin: 0;
  cursor: pointer;
}

/* Make sure everything is readable in Dark Mode if you use it */
._lightMode_ .contactus-page {
  background: #000;
}
._lightMode_ .contactus-form-wrapper {
  background: rgba(0, 0, 0, 0.7);
}
._lightMode_ .contactus-form-wrapper h2 {
  color: #fff;
}
._lightMode_ .content-page-from .form-control {
  background: #333;
  color: #fff;
  border-color: #555;
}
._lightMode_ .content-page-from .form-control:focus {
  border-color: #5B9311;
  box-shadow: 0 0 0 2px rgba(91, 147, 17, 0.2);
}
