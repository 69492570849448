/* Container for the verification section */
.verify-email-section {
  position: relative;
  padding: 80px 0;
  background-color: #f8f8f8; /* Light background that contrasts with your theme */
  margin-top: 80px;
  padding-top: 20px;
}

/* Background images */
.verify-email-section .bg-right-1,
.verify-email-section .bg-right-2,
.verify-email-section .bg-middle {
  position: absolute;
  opacity: 0.3;
  pointer-events: none;
}

.verify-email-section .bg-right-1 {
  top: 0;
  right: 0;
  width: 200px;
}

.verify-email-section .bg-right-2 {
  bottom: 0;
  right: 0;
  width: 200px;
}

.verify-email-section .bg-middle {
  top: 50%;
  left: 50%;
  width: 300px;
  transform: translate(-50%, -50%);
}

/* Card container for the verification content */
.verify-email-card {
  background: rgba(255, 255, 255, 0.85);
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  z-index: 10;
}

/* Logo styling */
.verify-email-card .logo-container {
  margin-bottom: 20px;
}

.verify-email-card .logo {
  max-width: 150px;
}

/* Heading and paragraph styling */
.verify-email-card h2 {
  color: #5b9311;
  font-family: Nunito, sans-serif;
  margin-bottom: 20px;
}

.verify-email-card p {
  font-family: Nunito, sans-serif;
  color: #333;
  margin-bottom: 30px;
  font-size: 1rem;
}

/* Button styling */
.verify-email-card .btn-verify {
  background-color: #5b9311 !important;
  border: none;
  font-family: Nunito, sans-serif;
  font-size: 1rem;
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
}

.verify-email-card .btn-verify:hover {
  background-color: #4a7a0e !important;
}

.verify-email-section {
  position: relative;
  padding: 80px 0;
  background-color: #f8f8f8;
  min-height: 80vh; /* Ensures the section covers the full viewport height */
  border: none; /* Remove any accidental borders */
}
