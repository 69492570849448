.blog-heading h2 {
    color: #000;
font-family: Nunito;
font-size: 50px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
._lightMode_ .blog-heading h2 {
    color: #FFF;
}
.blog-heading p {
    color: #000;
    font-family: Nunito;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
._lightMode_ .blog-heading p {
    color: #FFF;
}

.blog-box-left img {
    border-radius: 10px;
}
.blog-post-time p {
    color: #000;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    margin-bottom: 0;
}
.blog-post-time p span {
    color: #5B9311;
    text-decoration-line: underline;
text-transform: uppercase;
}
.blog-box-left h2 {
    color: #000;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.blog-read-more-button {
    color: #5B9311;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    text-decoration-line: underline !important;
}
.blog-main-box {
    max-width: 700px;
    width: 100%;
    margin: auto;
}
.blog-box-right h4 {
    color: #000;
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.blog-box-right ul li a {
    color: #5B9311;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline !important;
}
.blog-box-right ul {
    list-style: none;
}
.blog-box-right ul li {
    margin-bottom: 10px;
}
._lightMode_ .blog-post-time p {
color: #fff;
}
._lightMode_ .blog-box-left h2 {
    color: #fff;
}
._lightMode_ .blog-box-right h4 {
    color: #fff;
}