.login-section{
  margin-top: 40px;
  padding-top: 20px;
}

.login-section a{
font-size: 18px;
}

.signup-section{
  margin-top: 20px;
  padding-top: 20px;
}

.signup-section a{ 
  font-size: 16px;
}
.login-top-logo {
  text-align: center;
}
.login-top-logo img {
  max-width: 607px;
  width: 100%;
}
._lightMode_ .lite {
  display: none;
}
.login-from {
  border-radius: 16px;
  border: 1px solid #d4cece;
  background: rgba(255, 255, 255, 0.3); /* Semi-transparent white background */
  padding: 30px;
  margin: 0 auto; /* To center it if needed */
  position: relative;
  z-index: 20;
}
.login-from .form-label {
  color: #000;
  text-align: center;
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* Autofill styling for all states (autofill, hover, focus) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #ffffff; /* Ensure consistent text color */
  background: linear-gradient(
    180deg,
    rgba(99, 106, 150, 0.8) 0%,
    rgba(182, 186, 214, 0.5) 100%
  ) !important; /* Your custom background */
  border-radius: 25px; /* Maintain rounded edges */
  transition: background-color 5000s ease-in-out 0s; /* Smooth transition */
  box-shadow: 0 0 0 30px rgba(99, 106, 150, 0.8) inset !important; /* Shadow fix */
}

input:-webkit-autofill::first-line {
  font-family: Nunito, sans-serif; /* Ensure font consistency */
  font-size: 16px; /* Ensure font size consistency */
  color: #ffffff; /* Ensure placeholder and text color */
}

/* Ensure placeholder color remains the same */
input:-webkit-autofill::placeholder {
  color: #ffffff; /* Placeholder text color */
}
/* Style for form input fields */
.custom-input {
  border-radius: 25px; /* Rounded edges */
  border: none;
  padding: 10px 20px; /* Adjust padding for better spacing inside input */
  height: 45px; /* Adjust input height */
  background: linear-gradient(
    180deg,
    rgba(99, 106, 150, 0.8) 0%,
    rgba(182, 186, 214, 0.5) 100%
  ); /* Gradient background */
  font-family: Nunito, sans-serif; /* Font family */
  font-size: 16px; /* Font size */
  color: #ffffff; /* Text color */
  width: 320px; /* Adjust input width */
}
/* Placeholder styling */
.custom-input::placeholder {
  color: #ffffff; /* Placeholder color */
  font-weight: 300; /* Light font weight for placeholder */
}

/* Focused input styling */
.custom-input:focus {
  outline: none;
  border-color: #ffffff; /* Ensure border color remains consistent */
  box-shadow: 0 0 8px rgba(0, 68, 255, 0.3); /* Light shadow on focus */
}

.input-icon-wrapper {
  position: relative;
  width: 100%;
}

/* Style for the email input */
.email-input {
  padding-left: 50px; /* Increased padding to accommodate a bigger icon */
}

.password-input {
  padding-left: 50px; /* Increased padding to accommodate a bigger icon */
}

/* Positioning the icon */
.input-icon {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%); /* Centers the icon vertically */
  width: 35px; /* Increased size */
  height: 35px;
}

.input-icon-lock {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%); /* Centers the icon vertically */
  width: 23px; /* Increased size */
  height: 23px;
}
/* Style for placeholder text */
.form-control::placeholder {
  color: #676666;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.Signup-Button {
  border-radius: 20px;
  background: linear-gradient(
    90deg,
    rgba(235, 245, 255, 0.9) 0%,
    /* Very soft light blue */ rgba(250, 255, 255, 0.6) 100% /* Almost white */
  );
  padding: 8px 12px;
  margin: 10px 12px 10px 0;
  min-width: 313px;
  box-shadow: 0px 4px 15px rgba(0, 123, 255, 0.2); /* Subtle blue glow */
  transition: box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition for hover effects */
  border: none;
  color: #0022ce;
  font-family: Nunito, sans-serif;
  font-size: 22px;
  cursor: pointer;
}

/* Hover state */
.Signup-Button:hover {
  transform: scale(1.05); /* Scale the button up by 5% */
  box-shadow: 0px 6px 18px rgba(0, 123, 255, 0.3); /* Slightly stronger blue glow */
  transition: box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition for hover effects */
}

/* Active state */
.Signup-Button:active {
  border-radius: 20px;
  background: linear-gradient(
    90deg,
    rgba(235, 245, 255, 0.9) 0%,
    /* Very soft light blue */ rgba(250, 255, 255, 0.6) 100% /* Almost white */
  );
  padding: 8px 12px;
  margin: 10px 12px 10px 0;
  min-width: 313px;
  box-shadow: 0px 4px 15px rgba(0, 123, 255, 0.2); /* Subtle blue glow */
  transition: box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition for hover effects */
  border: none;
  color: #0022ce;
  font-family: Nunito, sans-serif;
  font-size: 22px;
  cursor: pointer;
}

.password {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.login-button-style {
  color: #5b9311;
  text-align: center;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline !important;
}
.form-check-label {
  color: #000;
  text-align: center;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 10px;
}

#formBasicCheckbox {
  border-radius: 3px;
  /* background: #D9D9D9; */
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.25) inset;
}
.from-bottom-text {
  color: #434141;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 2rem;
  margin-bottom: 35px;
}
.from-bottom-text span {
  color: #5b9311;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
}
.width-from {
  max-width: 567px;
  width: 100%;
  margin: auto;
}

.Continue-text {
  margin: 2rem;
  color: #434141;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.login-button button {
  border-radius: 9px;
  border: 1px solid #7d7979;
  background: #f2f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  text-align: center;
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1.5rem;
}
.login-button button img {
  width: 30px;
  margin-right: 10px;
}
.login-button button:hover {
  background: #f2f6f6;
  color: #000;
}

.continue-with-text {
  font-size: 16px;
  color: #333;

  margin-top: 20px;
}

.continue-with-google {
  display: flex;
  cursor: pointer;

  margin-top: 15px;
}

.google-icon {
  width: 30px;
  height: 30px;
  margin-right: 5px;
  margin-top: 5px;
}

.google-text {
  font-size: 18px;
  color: #333;
  margin: 0; /* Remove margin */
  padding: 0; /* Remove padding */
  line-height: 1.2; /* Adjust line height for better alignment */
}
/* drack */
._lightMode_ .login-from {
  border-radius: 16px;
  border: 1px solid #494949;
  background: #494949;
}
._lightMode_ .login-from .form-label {
  color: #fff;
}

._lightMode_ .login-from input::placeholder {
  color: #b9b3b3;
}
._lightMode_ .form-check-label {
  color: #fff;
}
._lightMode_ .from-bottom-text {
  color: #bfbdbd;
}
._lightMode_ .Continue-text {
  color: #fff;
}
._lightMode_ .login-button button {
  background: #0000;
  color: #fff;
}
._lightMode_ .login-from h4 {
  color: #fff;
}

.error-message {
  border-radius: 8px; /* Smooth rounded corners */
  padding: 12px 16px; /* Adequate spacing for clarity */
  margin-bottom: 16px; /* Space below the message */
  font-family: Nunito, sans-serif;
  font-size: 16px;
  text-align: center;

  /* Initial state (hidden & slightly raised) */
  opacity: 0;
  transform: translateY(-10px);

  /* Transition for fade-in & slide-down effect */
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* When the error is shown, bring it into view */
.error-message.show {
  opacity: 1;
  transform: translateY(0);
}

/* Style for error-type messages */
.error-message.error {
  background-color: #fdecea; /* Very light red */
  color: #b71c1c; /* Deep red text */
  border: 1px solid #f5c6cb; /* Soft border that complements the background */
  box-shadow: 0 2px 8px rgba(183, 28, 28, 0.1); /* Subtle shadow for depth */
}

/* Style for success-type messages (if needed) */
.error-message.success {
  background-color: #e7f5e6; /* Light green background */
  color: #2e7d32; /* Dark green text */
  border: 1px solid #c3e6cb; /* Matching border */
  box-shadow: 0 2px 8px rgba(46, 125, 50, 0.1); /* Subtle shadow for depth */
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*  */
.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
}
.hr-text:before {
  content: "";
  background: linear-gradient(to right, transparent, #818078, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}
.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  padding: 0 0.5em;
  line-height: 1.5em;
  color: #000;
  background-color: #f3f6f6;
}
.login-from h4 {
  margin-bottom: 30px;
  color: #000;

  font-family: Nunito;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
/* new style */
.signupbutton_loginpage {
  text-align: center;
  margin-top: 20px;
}
.signupbutton_loginpage button {
  width: 200px;
  margin-right: 0;
}

.splatter-image-middle-login {
  position: absolute;
  top: 0; /* Adjust this value to position the image vertically */
  right: 0; /* Adjust this value to position the image horizontally */
  width: 100vw; /* Adjust the size of the splatter image */
  height: 60vw;
  z-index: 0; /* Ensure the image is behind the text */
  opacity: 0.6; /* Adjust the opacity if needed */
  pointer-events: none; /* Prevents the image from interfering with any clicks */
}

.splatter-image-right-2-login {
  position: absolute;
  top: 0; /* Adjust this value to position the image vertically */
  right: 0; /* Adjust this value to position the image horizontally */
  width: 100vw; /* Adjust the size of the splatter image */
  height: 60vw;
  z-index: 0; /* Ensure the image is behind the text */
  opacity: 0.6; /* Adjust the opacity if needed */
  pointer-events: none; /* Prevents the image from interfering with any clicks */
}

.splatter-image-right-1-login {
  position: absolute;
  top: 0; /* Adjust this value to position the image vertically */
  right: 0; /* Adjust this value to position the image horizontally */
  width: 100vw; /* Adjust the size of the splatter image */
  height: 60vw;
  z-index: 0; /* Ensure the image is behind the text */
  opacity: 0.6; /* Adjust the opacity if needed */
  pointer-events: none; /* Prevents the image from interfering with any clicks */
}

.password-reset{
  margin-top: 40px;;
}

.custom-input.email-input::placeholder {
  font-size: 16px;
}