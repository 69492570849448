.payment-page {
  font-family: Nunito;
  margin-top: 80px;
  padding-top: 20px;
}
.payment-page-heading.text-center h2 {
  color: #000;
  text-align: center;
  font-family: Nunito;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 5vh;
}
.payment-page-heading.text-center p {
  color: #000;
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
  z-index: 20;
}
.payment-box {
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  background: #fff;
  padding: 11px;
}
.payment-box-content ul {
  list-style: none;
  padding-left: 0;
}
.payment-box-content img {
  width: 24px;
  margin-right: 5px;
}
.payment-box-top-heading h5 {
  color: #000;
  font-family: Nunito;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.payment-box-top-heading p {
  color: #000;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  margin-left: 14px;
  margin-right: 14px;
  height: 100px;
  position: relative;
}
.payment-box-content {
  margin-left: 14px;
  margin-right: 14px;
}
.payment-button-style {
  border-radius: 6px;
  background: #5b9311;
  width: 100%;
  display: block;
  text-align: center;
  color: #fff;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 7px;
}
.payment-box-content p {
  color: #000;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}
.payment-button-style-bottom {
  border-radius: 6px;
  background: #5b9311;
  color: #fff;
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 5px 18px;
}
.payment-box-content h2 span {
  color: #000;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}
.button-bottom-text p {
  color: #5b9311;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  margin-top: 10px;
  height: 50px;
}
.payment-box-content ul li {
  color: #000;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  display: flex;
  align-items: center;
}
.upi-payment {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.other-payment-button-style img {
  height: 50px;
  width: auto;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}
.other-payment h4 {
  color: #000;
  font-family: Nunito;
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
}
.credit-payment h4 {
  color: #000;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.card-payment-button-style img {
  height: 58px;
  width: auto;
  margin-right: 20px;
}
.credit-payment h5 {
  color: #000;
  font-family: Inter;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.credit-payment .form-label {
  color: #413e3e;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.credit-payment .form-check-label {
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.payment-details {
  width: 60%;
}
.payment-details-mony {
  color: #413e3e;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.payment-details-total {
  color: #413e3e;
  text-align: right;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.righ-img-other-payment {
  text-align: end;
}
.righ-img-other-payment img {
  height: 180px;
  width: auto;
}
.payment-box-content h2 {
  color: #000;
  font-family: Nunito;
  font-size: 30px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
.payment-box-content img {
  display: none;
}
.payment-box-content img.d {
  display: block;
}
/* drack mode */
._lightMode_ .payment-page-heading.text-center h2 {
  color: #fff;
}
._lightMode_ .payment-page-heading.text-center p {
  color: #fff;
}
._lightMode_ .payment-box {
  background: #494949;
  border: 1px solid #494949;
}
._lightMode_ .payment-box-content h2 span {
  color: #fff;
}
._lightMode_ .payment-box-top-heading h5 {
  color: #fff;
}
._lightMode_ .payment-box-top-heading p {
  color: #fff;
}
._lightMode_ .payment-box-content h2 {
  color: #fff;
}
._lightMode_ .payment-box-content p {
  color: #fff;
}
._lightMode_ .button-bottom-text p {
  color: #5b9311;
}
._lightMode_ .payment-box-content ul li {
  color: #fff;
}
._lightMode_ .other-payment h4 {
  color: #fff;
}
._lightMode_ .credit-payment h4 {
  color: #fff;
}
._lightMode_ .credit-payment h5 {
  color: #fff;
}
._lightMode_ .credit-payment .form-label {
  color: #858484;
}
._lightMode_ .credit-payment .form-check-label {
  color: #fff;
}
._lightMode_ .payment-details-mony {
  color: #858484;
}
._lightMode_ .payment-details-total {
  color: #858484;
}
._lightMode_ .payment-details hr {
  color: #d6cccc;
}
._lightMode_ .payment-box-content img {
  display: block;
}
/* new page style  */
.payment_month_and_year_button {
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  align-items: center;
  background: rgba(194, 235, 141, 0.6);
  border-radius: 50px;
  height: 65px;
  position: relative;
  z-index: 20;
}
.payment_month_and_year_button .form-check-input {
  border-radius: 50% !important;
  height: 30px;
  background-color: #fff !important;
  border: none !important;
  width: 30px;
  box-shadow: none !important;
  padding: 0px !important;
}
.payment_month_and_year_button .form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: url(../../img/icon/blackdot.png) !important;
}
.payment_month_and_year_button .form-check.form-check-inline {
  margin-bottom: 0;
}
.form-check-label {
  color: #000;
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
  margin-left: 10px;
}
.payment_month_and_year_button .form-check-label {
  color: #000;
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
  margin-left: 10px;
  margin-top: 2px;
}
.payment_month_and_year_button .form-check.form-check-inline:nth-child(4) {
  margin-right: 0;
  padding-right: 0;
}
.payment_month_and_year_button .form-check-inline {
  display: inline-block;
  padding-right: 1rem;
  margin-left: 1rem;
  position: relative;
}
.payment_month_and_year_button .form-check.form-check-inline:nth-child(1) {
  margin-left: 0rem;
}
.payment_month_and_year_button
  .form-check.form-check-inline:nth-child(1):before {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  right: 0;
  border-radius: 50%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 35%,
    rgba(215, 33, 255, 0) 100%
  );
}
.payment_month_and_year_button
  .form-check.form-check-inline:nth-child(2):before {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  right: 0;
  border-radius: 50%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 35%,
    rgba(215, 33, 255, 0) 100%
  );
}
.payment_month_and_year_button
  .form-check.form-check-inline:nth-child(3):before {
  content: "";
  width: 1px;
  height: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 35%,
    rgba(215, 33, 255, 0) 100%
  );
  position: absolute;
  right: 0;
  border-radius: 50%;
}
.payment_card_box {
  /* A softer, clean gradient in a green-ish palette */
  background: linear-gradient(
    135deg,
    #e7fff2 0%,
    /* Very light mint */ #fafefa 100% /* Almost white */
  );
  cursor: pointer;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  margin: 10px 0;
  margin-bottom: 10px;
  position: relative;
  z-index: 20;
  padding: 20px;
  height: 400px; /* Adjust height as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease-in-out, border 0.3s ease-in-out;

  /* Shadow Effect: subtle but noticeable */
  box-shadow: 8px -8px 15px rgba(255, 255, 255, 0.8),
    /* White shadow top-right */ 8px 8px 20px rgba(0, 0, 0, 0.2); /* Softer black shadow bottom-right */
}
.payment_card_box:hover {
  transform: scale(1.08); /* More prominent on hover */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
}

.popular-label {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #28a745; /* Green background */
  color: #fff; /* White text */
  font-size: 14px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  text-transform: uppercase;
}

/* Highlight popular plan with border */
.popular-plan {
  border: 3px solid #ffc107; /* Gold border */
  background: linear-gradient(
    135deg,
    #fff7e0,
    #ffefb8
  ); /* Subtle gold gradient */
  transform: scale(1.05); /* Slight enlargement */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 8px 20px rgba(255, 193, 7, 0.3); /* Golden glow shadow */
}

.popular-plan:hover {
  transform: scale(1.08); /* More prominent on hover */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
}

.payment_card_price {
  font-size: 12px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 0px;
  text-align: center;
}

.payment_card_title {
  font-size: 24px;
  color: #000000;
  text-align: center;
  margin-bottom: 10px;
}

.payment_card_box_body {
  color: #000000;
  text-align: center;
}

.payment_card_box_body h4 {
  font-size: 12px;
  margin-bottom: 15px;
}

.payment_card_box_body ul {
  padding-left: 0;
  list-style: none; /* Remove default bullet points */
}

.payment_card_box_body ul li {
  display: flex;
  align-items: center; /* Align the icon and text horizontally */
  margin-bottom: 10px; /* Add space between the items */
}

.payment_card_box_body ul li svg {
  color: #5c5c5c; /* Color for the tick icon */
  font-size: 18px; /* Adjust the size of the icon */
  margin-right: 10px; /* Add space between the icon and the text */
}

.payment_card_box_body ul li span {
  font-size: 16px; /* Text size for the features */
  color: #000; /* Color for the text */
}

.checkbox_button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* Updated gradient: calm greens */
  background-color: #b3e7c0;
  color: #000000; /* White text stands out on green */
  border: none;
  padding: 12px 24px;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 20px;
  /* Subtle initial shadow */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  /* Smooth transition for hover effects */
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  text-align: center;
}

/* Hover/Focus states: slight lift and stronger shadow */
.checkbox_button:hover,
.checkbox_button:focus {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

/* Base style for the custom checkbox */
.checkbox_button input[type="checkbox"] {
  appearance: none; /* Remove default styling */
  -webkit-appearance: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgba(68, 66, 178, 0.1);
  cursor: pointer;
  position: relative;
  margin-right: 6px;
  border: 2px solid #000000; /* Optional: Add a border for visibility */
}

/* Style for the checkbox when checked */
.checkbox_button input[type="checkbox"]:checked {
  background-color: rgba(
    255,
    255,
    255,
    0.7
  ); /* Change background color when checked */
}

/* Add the tick mark using ::after pseudo-element */
.checkbox_button input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  top: 7px; /* Adjust as needed */
  left: 11px; /* Adjust as needed */
  width: 6px;
  height: 12px;
  border: solid rgb(0, 0, 0);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox_button Form.Check {
  margin-right: 10px; /* Space between checkbox and text */
}

.checkbox_button span {
  margin-right: 10px; /* Add some space between the text and checkbox */
}
.payment_page_top_heading h2 {
  color: #000;
  text-transform: uppercase;
  font-weight: 700;
  position: relative;
  z-index: 20;
}

._lightMode_ .payment_page_top_heading h2 {
  color: #fff;
}
.bottom-button-more {
  background-color: #d4edda; /* Light green background */
  border: 1px solid #c3e6cb; /* Subtle border matching the background */
  color: #000000; /* Black text for contrast */
  padding: 12px; /* Comfortable padding */
  border-radius: 50px; /* Rounded edges for a modern look */
  font-weight: 600; /* Bold text for emphasis */
  text-align: center; /* Centered text */
  width: 100%; /* Full width */
  display: block; /* Block-level element */
  max-width: 500px; /* Limit maximum width */
  margin: auto; /* Center horizontally */
  margin-top: 30px; /* Add spacing above */
  z-index: 20; /* Ensure it stays on top */
  position: relative; /* Enable positioning */

  /* Refined Box Shadow */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
    /* Subtle shadow for depth */ 0 1px 3px rgba(0, 0, 0, 0.06); /* Lighter shadow for detail */

  /* Smooth transition for hover effects */
  transition: all 0.3s ease-in-out;
}

/* Hover Effect */
.bottom-button-more:hover,
.bottom-button-more:focus {
  background-color: #d4edda; /* Light green background */
  color: #000000; /* Keep the text black */
  transform: translateY(-2px); /* Subtle lift effect */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15),
    /* Slightly stronger shadow */ 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a bit more depth */
  border: 1px solid #c3e6cb; /* Subtle border matching the background */
}

.payment_card_box .form-check-input:checked[type="checkbox"] {
  --bs-form-check-bg-image: url(../../img/icon/blackdot.png) !important;
  background-size: 70%;
}

/* new data  */

.payment_button_tab_style li button {
  padding: 0;
  border: none !important;
}

.my-green-form-container {
  position: relative; /* or position: absolute, if you prefer */
  z-index: 20; /* a higher value than your BG images */
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  border-radius: 8px;
  padding: 1rem;
  width: fit-content;
  margin: 2rem auto;
  margin-bottom: 55px;
}

.my-green-form-list {
  list-style-type: disc; /* bullet points */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin: 0;
  padding: 0 2rem;
}

.my-green-form-list li {
  /* Each bullet item is displayed horizontally */
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #155724; /* darker green text */
  font-weight: bold;
}

.my-green-form-list li input[type="radio"] {
  accent-color: #28a745; /* sets radio bullet color (for browsers that support it) */
  transform: scale(1.2); /* make the radio a bit bigger */
}

.my-green-form-list li label {
  cursor: pointer;
}

.selected-box {
  border: 3px solid #28a745; /* Green border */
  transform: scale(1.05); /* Slight enlargement */
  transition: transform 0.3s ease, border 0.3s ease;
}

.selected-box:hover {
  transform: scale(1.08); /* Slightly larger on hover */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
}

.curric-title {
  position: relative;
  z-index: 20;
  font-size: 28px;
}

/* --- Cart Container at Bottom --- */
.selected-cart-container {
  /* If you want it to remain within the scroll flow, use sticky */
  position: relative;
  bottom: 0;
  width: 100%;

  /* Light green background & subtle border */
  background: #effff1;
  border-top: 2px solid #c3e6cb;
  padding: 1rem 0;
  margin-top: 2rem; /* space from above content */
  z-index: 99;

  /* Subtle shadow to separate from content */
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
}

.selected-cart-container h5 {
  margin-bottom: 1rem;
  font-weight: 700;
  color: #000;
}

/* The <ul> that holds the selected items */
.cart-items-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Each line item in the cart */
.cart-item {
  display: flex;
  justify-content: space-between;
  padding: 6px 0;
  border-bottom: 1px dashed #ccc;
}
.cart-item:last-child {
  border-bottom: none;
}

/* Plan title & price in each cart item */
.cart-plan-title {
  font-weight: 600;
  color: #2c2c2c;
}
.cart-plan-price {
  color: #2c2c2c;
  font-weight: 500;
}

/* "Proceed to Checkout" button in the cart */
.proceed-button {
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  color: #000;
  width: 100%;
  border-radius: 25px;
  font-weight: 600;
  padding: 10px 20px;
  transition: all 0.3s ease-in-out;
}
.proceed-button:hover {
  background-color: #c6e5cb;
  color: #000;
  border-color: #bcd7bf;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pricing-header {
  position: relative;
  z-index: 20;
}

.bottom-button-more:disabled {
  background-color: #ccc; /* lighter grey background */
  color: #666; /* grey text */
  cursor: not-allowed; /* "no" cursor style */
  opacity: 0.8; /* slight transparency if you want */
  box-shadow: none; /* remove hover shadow, if desired */
  border: none;
}

.loader-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* or whatever height you want */
  background-color: #fff; /* optional if you want a white backdrop */
}

.payment_card_box_body h5 {
  margin-bottom: 20px;
}

.payment_card_price p {
  font-size: 20px; /* or any size you prefer */
}

.interval-label {
  display: inline-flex;
  align-items: center;
  gap: 6px; /* spacing between text and badge */
}

/* Position the badge in the upper-right corner */
.discount-badge {
  background-color: red;
  color: white;
  font-weight: bold;
  font-size: 10px;
  padding: 2px 6px;
  border-radius: 4px;
  text-transform: uppercase;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* optional shadow */
}

@media (max-width: 766px) {
  .payment-page {
    margin-top: 0px;
    padding-top: 0px;
  }

  .my-green-form-container {
    width: 90%; /* Use 90% of the viewport width */
    padding: 0.5rem; /* Slightly reduce padding */
    margin: 1rem auto; /* Adjust margin */
    overflow-x: auto; /* Allow horizontal scrolling if items still exceed width */
  }

  .my-green-form-list {
    gap: 0.5rem; /* Reduce space between items */
    padding: 0 0.5rem; /* Reduce side padding */
  }

  /* Scale down the radio buttons and labels */
  .my-green-form-list li input[type="radio"] {
    transform: scale(1); /* Reduce from 1.2 to 1 for a smaller radio */
  }

  .my-green-form-list li label {
    font-size: 0.9rem; /* Slightly smaller font */
  }
  .discount-badge {
    display: none !important;
  }

  .payment_card_box {
    /* Allow the card to adjust its height based on content */
    height: 350px;
    /* Reduce padding for smaller screens */
    padding: 10px;
    /* Center the card with auto margins and reduce horizontal margins */
    margin: 10px auto;

    margin-bottom: 20px;
    /* Slightly reduce the rounded corners */
    border-radius: 15px;
    /* Adjust box-shadow for a softer effect */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

    width: 350px;
  }

  /* Modify the hover effect for mobile */
  .payment_card_box:hover {
    transform: scale(1.02); /* More subtle hover effect */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  /* Adjust inner text sizes for better mobile readability */
  .payment_card_price p {
    font-size: 16px;
  }

  .payment_card_title {
    font-size: 20px;
  }

  .payment_card_box_body h5 {
    font-size: 14px;
  }

  .payment_card_box_body ul li {
    font-size: 14px;
  }

  /* Scale down the subscribe button */
  .checkbox_button {
    font-size: 14px;
    padding: 8px 16px;
  }
}

 @media only screen and (min-width: 767px) and (max-width: 850px)  {
  .payment-page {
    margin-top: 0px;
    padding-top: 0px;
  }

  .payment_card_box {
    width: 100%;              /* Use full available width */
    max-width: 350px;         /* Cap the width for consistency */
    height: 200px;             /* Allow height to adapt to content */
    padding: 10px;            /* Reduced padding for a compact look */
    margin: 15px auto;        /* Center the card and add vertical spacing */
    border-radius: 15px;      /* Slightly smaller corner radius */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Softer shadow */

    /* Use flex layout to align the button at the bottom */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 400px;        /* Force a consistent minimum height */
  }

  .payment_card_box:hover {
    transform: scale(1.02);   /* More subtle hover effect */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  /* Adjust inner text sizes for better mobile readability */
  .payment_card_price p {
    font-size: 16px;
  }

  .payment_card_title {
    font-size: 20px;
  }

  .payment_card_box_body h5,
  .payment_card_box_body ul li {
    font-size: 14px;
  }

  /* Make the subscribe button a bit smaller for a uniform tap area */
  .checkbox_button {
    font-size: 14px;
    padding: 8px 14px;
  }

  /* Ensure the subscribe button is always pushed to the bottom */
  .payment_card_box button.checkbox_button {
    margin-top: auto;
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1100px) {
  /* Adjust the logo size */
  .payment-page {
    font-family: Nunito;
    margin-top: 80px;
    padding-top: 20px;
  }
  .payment_card_box {
    /* Make the card fluid within its container */
    width: 90%; /* Use 90% of the available width */
    max-width: 400px; /* Cap the maximum width as needed */
    height: auto; /* Let height adjust based on content */
    padding: 15px; /* Slightly reduce padding for a compact look */
    margin: 20px auto; /* Center horizontally and add vertical spacing */
    border-radius: 20px; /* A bit less rounding on tablets */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* A softer shadow */
  }

  /* More subtle hover effect on tablets */
  .payment_card_box:hover {
    transform: scale(1.03);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  }

  /* Adjust inner text sizes for tablet readability */
  .payment_card_price p {
    font-size: 18px;
  }

  .payment_card_title {
    font-size: 22px;
  }

  .payment_card_box_body h5,
  .payment_card_box_body ul li {
   
  }

  /* Adjust the subscribe button for a uniform tap area */
  .checkbox_button {
    font-size: 16px;
    padding: 10px 18px;
  }
}
