/* logo */

@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@700&display=swap');

.cube-container {
  width: 95px;
  height: 95px;
  position: relative;
  perspective: 1000px;
  margin:auto;
}
#cube {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  transform: translateZ(-50px ) rotateZ(45deg) rotateX(45deg);
  animation: spin 5s infinite linear;
}
@keyframes spin {
  0% { transform: translateZ( -50px ) rotateY(360deg) rotateZ(0deg) rotateX(45deg) }
  50% { transform: translateZ( -50px ) rotateY(180deg) rotateZ(180deg) rotateX(45deg) }
  100% { transform: translateZ( -50px ) rotateY(0deg) rotateZ(360deg) rotateX(45deg) }
}
#cube figure {
  width: 100%;
  height: 100%;
  position: absolute;
  line-height: 65px;
  background: #67b72d;
  font-weight: bold;
  display: flex;
  color: #fff;
  font-family: 'Tangerine', serif;
  font-size: 32px;
  text-shadow: 4px 4px 4px #777676;
  text-align: center;
  border-radius: 8px;
  backface-visibility: hidden;
  align-items: center;
  justify-content: center;
}
#cube .front  { transform: rotateY(   0deg ) translateZ( 50px ); }
#cube .back   { transform: rotateX( 180deg ) translateZ( 50px ); }
#cube .right  { transform: rotateY(  90deg ) translateZ( 50px ); }
#cube .left   { transform: rotateY( -90deg ) translateZ( 50px ); }
#cube .top    { transform: rotateX(  90deg ) translateZ( 50px ); }
#cube .bottom { transform: rotateX( -90deg ) translateZ( 50px ); }
.loader {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    position: relative;
    z-index: 200;
}