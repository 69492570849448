/* ========================================
   PAGE WRAPPER AND BACKGROUNDS
   ======================================== */

/* The outer section for the error page */
.error-page {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  margin-top: 80px;
  padding-top: 20px;
}

/* The background wrapper */
.error-background {
  position: relative;
  z-index: 20;
}

/* Optional: if you want the same background splatters
     as the success page, style them similarly: */
.splatter-image-right-error {
  position: absolute;
  top: 0;
  right: 0;
  /* Adjust size or z-index as needed */
}

.splatter-image-middle-error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Adjust size or z-index as needed */
}

/* ========================================
     MAIN ERROR BOX STYLES
     ======================================== */
.error-box {
  background-color: #ffffff;
  border-radius: 12px;
  min-height: 400px; /* Adjust as needed */
  padding: 2.5rem;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);

  /* Flex layout to align content horizontally */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

.error-box-left {
  min-width: 200px;
  margin-bottom: 1rem;
}

.error-box-right {
  flex: 1;
  min-width: 250px;
}

/* The vertical divider for larger screens */
.error-divider {
  width: 1px;
  height: 120px;
  background-color: #eaeaea;
  margin: 0 2rem;
}

/* ========================================
     ICON & TEXT STYLING
     ======================================== */
.error-icon {
  width: 140px;
  height: auto;
  margin-bottom: 1rem;
}

/* Payment Unsuccessful heading */
.error-title {
  font-size: 1.75rem;
  font-weight: 600;
  color: #d9534f; /* A typical "danger" color, adjust as needed */
  margin-bottom: 0.5rem;
}

/* Amount */
.error-amount {
  font-size: 2rem;
  font-weight: 700;
  color: #121212;
  margin-bottom: 0.5rem;
}

/* ========================================
     PAYMENT DETAIL LIST
     ======================================== */
.list-payment-box p {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
}

.list-payment-box .label {
  color: #707070;
  font-weight: 600;
  font-size: 1rem;
}

.list-payment-box .main-content {
  color: #333;
  font-size: 1rem;
}

/* ========================================
     RESPONSIVE TWEAKS
     ======================================== */
@media only screen and (max-width: 767px) {
  .error-box {
    flex-direction: column;
    text-align: center;
  }

  .error-divider {
    display: none;
  }

  .error-page {
    margin-top: 0px;
    padding-top: 0px;
  }
}

@media (max-width: 850px) {
  .error-page {
    margin-top: 0px;
    padding-top: 0px;
  }

  .error-box {
    flex-direction: column;          /* Stack items vertically */
    justify-content: center;         /* Center content vertically */
    align-items: center;             /* Center content horizontally */
  }
  
  .error-box-left,
  .error-box-right {
    width: 100%;                     /* Make each section full width */
    text-align: center;              /* Center text within each section */
    margin: 0 auto;                  /* Center the containers */
  }
  
  /* Hide the vertical divider on smaller screens */
  .error-divider {
    display: none  !important;
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1100px) {

  .error-box {
    flex-direction: column;          /* Stack items vertically */
    justify-content: center;         /* Center content vertically */
    align-items: center;             /* Center content horizontally */
  }
  
  .error-box-left,
  .error-box-right {
    width: 100%;                     /* Make each section full width */
    text-align: center;              /* Center text within each section */
    margin: 0 auto;                  /* Center the containers */
  }
  
  /* Hide the vertical divider on smaller screens */
  .error-divider {
    display: none  !important;
  }
}
