/* Wrapper */
.speaks-wrapper {

  color: #000; /* Black text */
  text-align: center;
  padding: 60px 40px; /* More padding for breathing space */
  border-radius: 12px;
  max-width: 900px; /* Increased width */
  margin: 40px auto; /* More margin for spacing */
  margin-bottom: 60px;
  
}

/* Title */
.title {
  font-family: "Nunito", sans-serif;
  font-size: 3.5rem; /* Larger title */
  font-weight: bold;
  margin-bottom: 40px; /* More space below title */
  color: #000;
}

/* Content */
.speaks-content {
  font-family: "Nunito", sans-serif;
  font-size: 1.35rem; /* Slightly larger text */
  line-height: 2; /* Increased line height for better readability */
  color: #000000;
  z-index: 20;
  position: relative;
}

.description {
  margin-bottom: 35px; /* More space between paragraphs */
  padding: 40px; /* Padding inside the background */
  background: rgba(255, 255, 255, 0.3); /* Light gray background */
  border-radius: 8px; /* Rounded corners for text blocks */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
  line-height: 1.8; /* Slightly tighter line spacing */
  border-radius: 13px;
  backdrop-filter: blur(10px); /* Apply a blur effect */
-webkit-backdrop-filter: blur(10px); /* For Safari */
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
border: 1px solid rgba(255, 255, 255, 0.2);


}


.highlight {
  color: #28a745; /* Green for price highlight */
  font-weight: bold;
}

/* Button */
.cta-button {
  display: inline-block;
  background: linear-gradient(
    90deg,
    rgba(235, 245, 255, 0.9) 0%,
     rgba(250, 255, 255, 0.6) 100% 
  );
  color: #000000; /* White text */
  font-weight: bold;
  font-size: 1.2rem; /* Slightly larger button text */
  padding: 15px 35px; /* Larger button for better emphasis */
  border-radius: 50px; /* Rounded */
  text-decoration: none;
  transition: all 0.3s ease;
 box-shadow: 0px 4px 15px rgba(0, 123, 255, 0.2);
}

.cta-button:hover {
  background: #acd8b6; /* Darker green */
  box-shadow: 0 8px 20px rgba(33, 136, 56, 0.5);
  color: #000000; /* Light text on hover */
}

/* Tablet responsiveness (up to 768px) */
@media only screen and (max-width: 768px) {
  .speaks-wrapper {
    padding: 40px 30px;
    max-width: 90%;
    margin: 30px auto;
  }
  
  .title {
    font-size: 3rem; /* slightly smaller title */
    margin-bottom: 30px;
  }
  
  .speaks-content {
    font-size: 1.2rem;
    line-height: 1.8;
  }
  
  .description {
    padding: 30px;
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 30px;
  }
  
  .cta-button {
    font-size: 1.1rem;
    padding: 12px 30px;
  }
}

/* Mobile responsiveness (up to 600px) */
@media only screen and (max-width: 600px) {
  .speaks-wrapper {
    padding: 20px 15px;
    max-width: 100%;
    margin: 20px auto;
  }
  
  .title {
    font-size: 2.5rem; /* smaller title for mobile */
    margin-bottom: 20px;
  }
  
  .speaks-content {
    font-size: 1rem;
    line-height: 1.5;
  }
  
  .description {
    padding: 15px;
    font-size: 0.9rem;
    line-height: 1.4;
    margin-bottom: 15px;
  }
  
  .cta-button {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
}
