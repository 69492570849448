/* similarquestion.css */

.formula-button {
    font-size: 16px;
    font-family: "Nunito", sans-serif;
    color: black;
    position: absolute;
    top: 2vh;
    right: 2vw;
    padding: 10px 20px;
    background-color: rgba(63, 168, 26, 0.35);
    border-radius: 10px;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-weight: 600;
  }
  
  .additional-buttons {
    margin-top: 20px;
    display: flex;
    gap: 10px;
  }
  
  .additional-button {
    font-size: 16px;
    font-family: "Nunito", sans-serif;
    color: black;
    padding: 10px 20px;
    background-color: rgba(63, 168, 26, 0.35);
    border-radius: 5px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    width: 20%;
    text-align: center;
  }
  
  /* Add more styles as needed */
  