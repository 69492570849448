.questionbank-section {
  background: linear-gradient(180deg, #deeff6 0%, #fbfff5 100%);
  margin-top: 80px;
  padding-top: 20px;
}

.qu-box {
  border-radius: 13px;
  background: linear-gradient(180deg, #dff0f7 0%, #fff 100%);
  padding: 30px;
  display: block;
  position: relative;
  z-index: 20;
  opacity: 0.9; /* 70% opacity */
  width: 400px;
  height: 150px;
}
.qu-box h4 {
  color: #000;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.large-icon {
  display: block; /* Ensures the image takes up the full width of its parent element */
}

/* dark */
._lightMode_ .top-header-quetion {
  background: #0000;
}
._lightMode_ .qu-box {
  background: #494949;
}
._lightMode_ .qu-box h4 {
  color: #fff;
}
.questionbank-filter-top-heading {
  border-radius: 13px;
  background: linear-gradient(180deg, #dff0f7 0%, #fff 100%);
  padding: 30px 20px;
}
.question-main-box.quetion-bank .question-main-box-style {
  background: linear-gradient(180deg, #dff0f7 0%, #fafdff 100%);
}
.questionbank-main-box-style {
  border-radius: 5px;
  width: 100%;
  background: linear-gradient(180deg, #dff0f7 0%, #fafdff 100%);
  padding: 20px;
  margin-bottom: 2rem;
}
._lightMode_ .questionbank-main-box-style {
  background: #494949;
}
._lightMode_ .questionbank-filter-top-heading {
  background: #494949;
}
.btn-popup-button-full-img {
  background: #e7e7e7;
  padding: 8px;
}

.top-nav {
  margin-top: 10px;
}

.ap-heading p {
  margin-left: 0px; /* Move to the left by 20px */
  padding-right: 20px; /* Optional: Add padding on the right to maintain balance */
  z-index: 20;
  position: relative;
  margin-bottom: 0px; /* Optional: Add space below the heading */
}

.ap-heading h2 {
  font-size: 2.5rem; /* Adjust the size as needed */
  font-weight: 700; /* 700 is usually considered bold */
  line-height: 1.2; /* Adjust line-height if needed */
  margin-bottom: 20px; /* Optional: Add space below the heading */
  color: #333; /* Optional: Darker color for better readability */
}

.top-nav {
  position: relative;
  z-index: 50;
}

@media (max-width: 767px) {
  .qu-box {
    height: auto; /* Allow height to adjust to content */
    padding: 20px; /* Adjust padding as needed */
    margin: 0 auto 20px auto; /* Center horizontally and add 20px bottom margin */
  }

  .qu-box h4 {
    font-size: 18px; /* Slightly smaller heading for mobile */
    margin-bottom: 10px; /* Some space below the heading if needed */
  }

  .ap-heading p {
    font-size: 14px;
  }
}

@media (max-width: 850px) {
  /* Adjust the logo size */
  .questionbank-section {
    background: linear-gradient(180deg, #deeff6 0%, #fbfff5 100%);
    margin-top: 0px;
    padding-top: 0px;
  }

  .qu-box {
    min-width: 195px;
    width: auto;
    /* Remove the fixed height by setting height to auto */
    height: auto; /* This ensures the container grows with its content */
    min-height: 100px;
    padding: 25px;
    margin: 0 auto 30px auto;
  }
  
  .qu-box h4 {
    font-size: 15px; /* Optionally, reduce the font size if needed */
  }

  
}

@media only screen and (min-width: 1001px) and (max-width: 1100px) {
  /* Adjust the logo size */
  .Myprofile-section {
    margin-top: 30px;
    padding-top: 30px;
  }

  .qu-box {
    width: 90%; /* Use 90% of the container’s width */
    width: 250px; /* Cap the maximum width to 400px */
    height: 150px; /* Let the height adjust to the content */
    padding: 25px; /* Slightly reduce the padding for a tighter look */
    margin: 0 auto 30px auto; /* Center the box horizontally and add more vertical spacing */
  }
}
