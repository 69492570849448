.curriculum-squares {
    transition: all 0.3s ease;
    overflow: visible;
}

.curriculum-squares rect {
    transition: transform 0.5s ease;
}

.curriculum-squares .square-text {
    opacity: 0; /* Hide text by default */
    transition: opacity 0.5s ease, transform 0.5s ease; /* Smooth transition for text */
}



.curriculum-squares .default-title {
    opacity: 1; /* Show the default title by default */
    transition: opacity 0.5s ease; /* Smooth transition for default title */
    cursor: pointer;
}

.curriculum-squares:hover .default-title {
    opacity: 0; /* Hide the default title on hover */
}

.curriculum-squares:hover .square-0 {
    transform: translate(-200px, -200px); /* Move top-left square further away */
}

.curriculum-squares:hover .square-1 {
    transform: translate(200px, -200px); /* Move top-right square further away */
}

.curriculum-squares:hover .square-2 {
    transform: translate(0px, 0px); /* Keep the middle square in place */
}

.curriculum-squares:hover .square-3 {
    transform: translate(-200px, 200px); /* Move bottom-left square further away */
}

.curriculum-squares:hover .square-4 {
    transform: translate(200px, 200px); /* Move bottom-right square further away */
}

.curriculum-squares:hover .square-group-0 .square-text {
    opacity: 1;
    transform: translate(-200px, -200px); /* Match text position to square */
}

.curriculum-squares:hover .square-group-1 .square-text {
    opacity: 1;
    transform: translate(200px, -200px); /* Match text position to square */
}

.curriculum-squares:hover .square-group-2 .square-text {
    opacity: 1;
    transform: translate(0px, 0px); /* Match text position to square */
}

.curriculum-squares:hover .square-group-3 .square-text {
    opacity: 1;
    transform: translate(-200px, 200px); /* Match text position to square */
}

.curriculum-squares:hover .square-group-4 .square-text {
    opacity: 1;
    transform: translate(200px, 200px); /* Match text position to square */
}
