.search-bar-container {
  /* Let Framer Motion handle the dynamic height */
  display: flex;              /* Make container a flex column */
  flex-direction: column;     
  width: 600px;               /* A nice default width */
  max-width: 90%;             /* Responsive fallback */
  margin: 0 auto;             /* Center horizontally */
  font-family: Nunito, sans-serif;
  font-size: 18px;
  font-weight: 500;
  border: 1px solid #cccccc;
  color: #000;
  border-radius: 20px;
  overflow: hidden;
  z-index: 9999;
  position: relative;
  background: #fff;
  transition: width 0.3s ease; /* Smoother expansion in width if changed */
}

.search-input-container {
  width: 100%;
  /* The height for the top input section is effectively 50px via input. */
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 15px;
}

.search-icon {
  position: absolute;
  left: 15px;
  color: #5B9311;
  font-size: 22px;
  vertical-align: middle;
}

.search-input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #12112e;
  font-weight: 500;
  background-color: transparent;
  padding-left: 40px; /* Account for icon */
  height: 50px;       /* Keep a consistent input height */
}

.search-input::placeholder {
  color: #a9a9a9;
  transition: all 250ms ease-in-out;
}

.close-icon {
  color: #a9a9a9;
  font-size: 20px;
  vertical-align: middle;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  margin-left: 10px;
}

.close-icon:hover {
  color: #7a7a7a;
}

.line-separator {
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
}

.search-content {
  /* This section grows & can scroll if results are big */
  flex: 1;               /* Occupies the remaining vertical space */
  display: flex;
  flex-direction: column;
  padding: 1em;
  overflow-y: auto;      /* Allows scrolling if content is larger than container */
  position: relative;

}

.loading-wrapper {
  width: 100%;
  flex: 1;              /* Fill available space */
  display: flex;
  align-items: center;
  justify-content: center;
}

.warning-message {
  color: #a1a1a1;
  font-size: 14px;
  display: flex;
  align-self: center;
}

@media only screen and (min-width: 601px) and (max-width: 1100px) {
  /* Adjust the logo size */
  .search-bar-container {
    width: 500px;
  }
}
