.Pagenotfound {
  /* background-color: #C8CFBE; */
  height: 650px;
  width: 100%;
  position: relative;
}
/* .pagenotfoundheader .text-end {
  background-color: #c8cfbe;
} */
.Pagenotfound_hingh_full {
  height: 100vh;
}
.background-img-notfound {
  position: absolute;
  width: 100%;
  height: 100%;
}
.Pagenotfound .container {
  height: 100%;
}
.Pagenotfound .container .row {
  height: 100%;
}
.page-not-found-content {
  position: relative;
  z-index: 20; 
}
.page-not-found-content h2 {
  color: #000;
  font-size: 58px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.page-not-found-content h4 {
  color: #000;
  font-size: 56px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}
.page-not-found-content p {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 136.8%;
  margin-top: 30px;
}
.page-not-found-to-go-back {
  background: #5b9311;
  color: #fff;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 12px 60px;
  margin-top: 80px;
  display: block;
  width: fit-content;
}
._lightMode_ .page-not-found-content h2 {
  color: #fff;
  font-weight: 500;
}

._lightMode_ .page-not-found-content h4 {
  color: #fff;
}
._lightMode_ .page-not-found-content p {
  color: #fff;
}
