/* lite color */

.hero-section {
  width: 100%;
  background-color: #f0f0f0; /* Replace with your desired color */
  position: relative;
  margin-top: 10%;
  padding-top: 10%;
}

.squares-art-background {
  position: absolute;
  top: 5vh;
  left: 25vw;
  z-index: 20; /* Places it behind other elements */
  max-width: 50% !important;
}

.cirriculum-squares-background {
  position: absolute;
  top: 15vh;
  right: 20vw;
  z-index: 50; /* Places it behind other elements */
}

section.hero-section {
  margin-top: 80px;
  padding-top: 20px;
}

.splatter-image-right-1 {
  position: absolute;
  top: 0; /* Adjust this value to position the image vertically */
  right: 0; /* Adjust this value to position the image horizontally */
  width: 600px; /* Adjust the size of the splatter image */
  z-index: 2; /* Ensure the image is behind the text */
  opacity: 0.8; /* Adjust the opacity if needed */
  pointer-events: none; /* Prevents the image from interfering with any clicks */
}

.splatter-image-right-2 {
  position: absolute;
  top: 0; /* Adjust this value to position the image vertically */
  right: 0; /* Adjust this value to position the image horizontally */
  width: 1000px; /* Adjust the size of the splatter image */
  z-index: 0; /* Ensure the image is behind the text */
  opacity: 0.6; /* Adjust the opacity if needed */
  pointer-events: none; /* Prevents the image from interfering with any clicks */
}

.splatter-image-middle {
  position: absolute;
  top: 0; /* Adjust this value to position the image vertically */
  left: 0; /* Adjust this value to position the image horizontally */
  width: 99vw; /* Adjust the size of the splatter image */
  z-index: 0; /* Ensure the image is behind the text */
  opacity: 0.6; /* Adjust the opacity if needed */
  pointer-events: none; /* Prevents the image from interfering with any clicks */
}

.splatter-image-bottom-right {
  position: absolute;
  top: 0; /* Adjust this value to position the image vertically */
  right: 0; /* Adjust this value to position the image horizontally */
  width: 800px; /* Adjust the size of the splatter image */
  z-index: 0; /* Ensure the image is behind the text */
  opacity: 0.6; /* Adjust the opacity if needed */
  pointer-events: none; /* Prevents the image from interfering with any clicks */
}

.splatter-image-left {
  position: absolute;
  top: 0; /* Adjust this value to position the image vertically */
  left: 0; /* Adjust this value to position the image horizontally */
  width: 800px; /* Adjust the size of the splatter image */
  z-index: 0; /* Ensure the image is behind the text */
  opacity: 0.6; /* Adjust the opacity if needed */
  pointer-events: none; /* Prevents the image from interfering with any clicks */
}

.hero-h1 h1 {
  color: #000;
  font-family: Nunito;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 5vh;
  z-index: 20;
  position: relative;
}
.hero-left p {
  color: #000;
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  z-index: 20;
  position: relative;
}
.hero-left p span {
  color: #5fa408;
}
.hero-left {
  margin-top: 15px;
}
.hreo-button-style {
  width: 100px;
  height: 40px;
  border-radius: 7px;
  background: #bae287;
  display: flex;
  color: #000;
  text-align: justify;
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  text-decoration: none;
  font-weight: 400;
  line-height: normal;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
  margin-bottom: 25px;
  z-index: 10;
}
.hreo-button {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 5rem;
}
.hreo-button-bottom a {
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, navy, black);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Nunito, sans-serif;
  font-size: 26px;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  line-height: 57px; /* Center the text vertically */
}

.hreo-button-bottom {
  max-width: 500px;
  width: 100%;
  height: 57px;
  border-radius: 20px;   
  background: linear-gradient(
    90deg, /* Gradient direction */
    rgba(29, 168, 255, 0.6) 0%,   /* 60% opacity for #1DA8FF */
    rgba(177, 111, 255, 0.6) 33%, /* 60% opacity for #B16FFF */
    rgba(246, 97, 134, 0.6) 68%,  /* 60% opacity for #F66186 */
    rgba(254, 169, 6, 0.6) 100%   /* 60% opacity for #FEA906 */
  ) !important;
  backdrop-filter: blur(10px); /* Optional: Add a blur effect */
  opacity: 0.9;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
  position: relative;
  z-index: 40;
}
/* dark */
._lightMode_ .hero-h1 h1 {
  color: #fff;
}
._lightMode_ .hero-left p {
  color: #fff;
}
._lightMode_ .hreo-button-style {
  background: #5b9311;
  color: #fff;
}


/* Media Query for screens smaller than 768px */
@media (max-width: 768px) {
  /* Hide the desktop hero section on mobile */
  .hero-section {
    margin-top: 0;
    padding-top: 0;
  }

  section.hero-section {
    margin-top: 0px;
    padding-top: 0px;
  }
  
  /* Show the mobile view */
  .mobile-view {
    display: block;
    padding: 20px 10px;
  }
  
  /* Adjust headings and paragraph sizes */
  .hero-h1 h1 {
    font-size: 30px; /* smaller heading */
    
  }
  
  .hero-left p {
    font-size: 16px;
  }
  
  /* Adjust buttons for mobile */
  .hreo-button-style {
    width: 80px;
    height: 35px;
    font-size: 16px;
    margin-right: 15px;
    margin-bottom: 15px;
  }
  
  .hreo-button {
    margin-top: 2rem;
  }
  
  .hreo-button-bottom {
    max-width: 100%;
    height: 45px;
    line-height: 45px;
    font-size: 20px;
    border-radius: 15px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  
  /* Optionally hide background splatter images on mobile for a cleaner look */
 
  /* Adjust container padding/margin if needed */
  .my-container {
    padding: 0 15px;
  }

  .cirriculum-squares-background {
    display: none;
  }

  .hreo-button-bottom {
    max-width: 100%;
    width: 100%;
    height: auto;          /* Let height adjust to content */
    padding: 10px 0;       /* Add vertical padding for better touch targets */
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-radius: 15px;
  }
  
  .hreo-button-bottom a {
    display: block;
    width: 100%;
    /* Remove fixed height and line-height */
    font-size: 20px;       /* Slightly smaller font for mobile */
    line-height: normal;   /* Let the padding define the button's height */
    padding: 10px 20px;    /* Add some padding */
    text-align: center;
    text-decoration: none;
    /* Optionally adjust the background or gradient if needed */
    background: linear-gradient(180deg, navy, black);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}


@media (max-width: 850px) { 
  /* Adjust the logo size */
  section.hero-section {
    margin-top: 0px;
    padding-top: 0px;
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1100px) {
  /* Adjust the logo size */
  section.hero-section {
    margin-top: 30px;
    padding-top: 30px;
  }
}