.charting-top-box-wh {
    height: 143px;
    background: #E3F3D7;
}

.charting-background {
  position: absolute;
  top: 50%;
  left: 100%; /* Start from the far right */
  width: 200vw;
  height: 250vh;
  background-image: url('../img/Charting Bg.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right center; /* Position the background to the right */
  transform: translate(-100%, -50%); /* Move it to the left and vertically center it */
  z-index: -1; /* Ensure it stays behind other content */
}

.charting-container {
  position: relative; /* Establish a containing block for the background */
}

.splatter-image-middle-2 {
  position: absolute;
  top: -15vh; /* Adjust this value to position the image vertically */
  left: 0; /* Adjust this value to position the image horizontally */
  width: 99vw; /* Adjust the size of the splatter image */
  z-index: 0; /* Ensure the image is behind the text */
  opacity: 0.6; /* Adjust the opacity if needed */
  pointer-events: none; /* Prevents the image from interfering with any clicks */
}

.title {
    color: #000;
    font-family: Nunito;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    z-index: 20;
    position: relative;
}
.charting-right-content {
    text-align: center;
}
.charting-left-img {
    border-radius: 20px;
    z-index: 10;
    position: relative;
}
.charting-left-img img {
    border-radius: 20px;
    background: lightgray 50% / cover no-repeat;
    box-shadow: 5px 5px 13px 0px rgba(0, 0, 0, 0.25);
    
}
.charting-right-content h4 {
    color: #000;
    font-family: Nunito;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    z-index: 20;
    position: relative;
}
.charting-right-content p {
    width: 85%;
    margin: auto;
    color: #000;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    z-index: 20;
    position: relative;
}
._lightMode_ .title {
    color: #fff;
}
._lightMode_ .charting-right-content h4 {
    color: #fff;
}
._lightMode_ .charting-right-content p {
    color: #fff;
}

