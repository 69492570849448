/* Base Styles */
.apcoursecard-box {
    border-radius: 13px;
    background: rgba(199, 198, 198, 0.5);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    padding: 20px;
    margin-bottom: 50px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 20;
  }
  
  .apcoursecard-box h4 {
    font-family: Nunito, sans-serif;
    font-size: 22px;
    font-weight: 600;
    text-align: left;
    color: inherit; /* Allow theme colors */
  }
  
  .apcoursecard-box-content {
    list-style: none;
    margin-top: 2rem;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .apcoursecard-box-content-button {
    margin-bottom: 1rem;
    text-align: left;
  }
  
  .apcoursecard-navlink {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: 'Nunito', sans-serif;
    color: #fff;
    text-decoration: none;
    width: 250px;
    padding: 10px 15px;
    border-radius: 10px;
    transition: background-color 0.3s;
  }
  
  .apcoursecard-navlink:hover {
    opacity: 0.9;
  }
  
  .apcoursecard-icon-wrapper {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }
  
  .apcoursecard-icon-wrapper img {
    width: 20px;
    height: auto;
  }
  
  /* Theme Styles with Opacity */
  
  /* Purple Theme */
  .apcoursecard-box.purple-theme h4 {
    color: #6708B1;
  }
  
  /* Opacity levels for navlinks and icons */
  .apcoursecard-box.purple-theme .apcoursecard-box-content-button:nth-child(1) .apcoursecard-navlink,
  .apcoursecard-box.purple-theme .apcoursecard-box-content-button:nth-child(1) .apcoursecard-icon-wrapper {
    background-color: rgba(103, 8, 177, 0.75); /* Most transparent */
  }
  
  .apcoursecard-box.purple-theme .apcoursecard-box-content-button:nth-child(2) .apcoursecard-navlink,
  .apcoursecard-box.purple-theme .apcoursecard-box-content-button:nth-child(2) .apcoursecard-icon-wrapper {
    background-color: rgba(103, 8, 177, 0.75);
  }
  
  .apcoursecard-box.purple-theme .apcoursecard-box-content-button:nth-child(3) .apcoursecard-navlink,
  .apcoursecard-box.purple-theme .apcoursecard-box-content-button:nth-child(3) .apcoursecard-icon-wrapper {
    background-color: rgba(103, 8, 177, 0.75);
  }
  
  .apcoursecard-box.purple-theme .apcoursecard-box-content-button:nth-child(4) .apcoursecard-navlink,
  .apcoursecard-box.purple-theme .apcoursecard-box-content-button:nth-child(4) .apcoursecard-icon-wrapper {
    background-color: rgba(103, 8, 177, 0.75); /* Least transparent */
  }
  
  /* Blue Theme */
  .apcoursecard-box.blue-theme h4 {
    color: #275285;
  }
  
  .apcoursecard-box.blue-theme .apcoursecard-box-content-button:nth-child(1) .apcoursecard-navlink,
  .apcoursecard-box.blue-theme .apcoursecard-box-content-button:nth-child(1) .apcoursecard-icon-wrapper {
    background-color: rgba(39, 82, 133, 0.75);
  }
  
  .apcoursecard-box.blue-theme .apcoursecard-box-content-button:nth-child(2) .apcoursecard-navlink,
  .apcoursecard-box.blue-theme .apcoursecard-box-content-button:nth-child(2) .apcoursecard-icon-wrapper {
    background-color: rgba(39, 82, 133, 0.75);
  }
  
  .apcoursecard-box.blue-theme .apcoursecard-box-content-button:nth-child(3) .apcoursecard-navlink,
  .apcoursecard-box.blue-theme .apcoursecard-box-content-button:nth-child(3) .apcoursecard-icon-wrapper {
    background-color: rgba(39, 82, 133, 0.75);
  }
  
  .apcoursecard-box.blue-theme .apcoursecard-box-content-button:nth-child(4) .apcoursecard-navlink,
  .apcoursecard-box.blue-theme .apcoursecard-box-content-button:nth-child(4) .apcoursecard-icon-wrapper {
    background-color: rgba(39, 82, 133, 0.75);
  }
  
  /* Green Theme */
  .apcoursecard-box.green-theme h4 {
    color: #497B22;
  }
  
  .apcoursecard-box.green-theme .apcoursecard-box-content-button:nth-child(1) .apcoursecard-navlink,
  .apcoursecard-box.green-theme .apcoursecard-box-content-button:nth-child(1) .apcoursecard-icon-wrapper {
    background-color: rgba(73, 123, 34, 0.75);
  }
  
  .apcoursecard-box.green-theme .apcoursecard-box-content-button:nth-child(2) .apcoursecard-navlink,
  .apcoursecard-box.green-theme .apcoursecard-box-content-button:nth-child(2) .apcoursecard-icon-wrapper {
    background-color: rgba(73, 123, 34, 0.75);
  }
  
  .apcoursecard-box.green-theme .apcoursecard-box-content-button:nth-child(3) .apcoursecard-navlink,
  .apcoursecard-box.green-theme .apcoursecard-box-content-button:nth-child(3) .apcoursecard-icon-wrapper {
    background-color: rgba(73, 123, 34, 0.75);
  }
  
  .apcoursecard-box.green-theme .apcoursecard-box-content-button:nth-child(4) .apcoursecard-navlink,
  .apcoursecard-box.green-theme .apcoursecard-box-content-button:nth-child(4) .apcoursecard-icon-wrapper {
    background-color: rgba(73, 123, 34, 0.75);
  }
  
  /* Red Theme */
  .apcoursecard-box.red-theme h4 {
    color: #970000;
  }
  
  .apcoursecard-box.red-theme .apcoursecard-box-content-button:nth-child(1) .apcoursecard-navlink,
  .apcoursecard-box.red-theme .apcoursecard-box-content-button:nth-child(1) .apcoursecard-icon-wrapper {
    background-color: rgba(151, 0, 0, 0.75);
  }
  
  .apcoursecard-box.red-theme .apcoursecard-box-content-button:nth-child(2) .apcoursecard-navlink,
  .apcoursecard-box.red-theme .apcoursecard-box-content-button:nth-child(2) .apcoursecard-icon-wrapper {
    background-color: rgba(151, 0, 0, 0.75);
  }
  
  .apcoursecard-box.red-theme .apcoursecard-box-content-button:nth-child(3) .apcoursecard-navlink,
  .apcoursecard-box.red-theme .apcoursecard-box-content-button:nth-child(3) .apcoursecard-icon-wrapper {
    background-color: rgba(151, 0, 0, 0.75);
  }
  
  .apcoursecard-box.red-theme .apcoursecard-box-content-button:nth-child(4) .apcoursecard-navlink,
  .apcoursecard-box.red-theme .apcoursecard-box-content-button:nth-child(4) .apcoursecard-icon-wrapper {
    background-color: rgba(151, 0, 0, 0.75);
  }
  

  @media (max-width: 850px) {
   
  
    .apcoursecard-box {
      padding: 15px; /* Slightly reduce padding if needed */
      box-sizing: border-box;
      width: 250px;
    }
    
    /* Add gap between each button container */
    .apcoursecard-box-content {
      display: flex;
      flex-direction: column;
      gap: 10px; /* Adjust the gap as needed */
    }
    
    .apcoursecard-box-content-button {
      width: 100%;
      box-sizing: border-box;
    }
    
    .apcoursecard-navlink {
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;
      font-size: 16px;
    }
  }
  
  @media only screen and (min-width: 1001px) and (max-width: 1100px) {
    .apcoursecard-box {
      padding: 15px; /* Slightly reduce padding if needed */
      box-sizing: border-box;
    }
    
    /* Adjust the container for each button, if necessary */
    .apcoursecard-box-content-button {
      width: 100%;
      box-sizing: border-box;
    }
    
    /* Make the navlinks responsive so they fit within the container */
    .apcoursecard-navlink {
      width: 100%;      /* Let the button take up the full available width */
      max-width: 100%;  /* Prevent any overflow */
      box-sizing: border-box;
      font-size: 16px;  /* Optionally reduce font size for a better fit */
    }
  }
  