/* .ap:before {
    content: '';
    background: linear-gradient(180deg, #ECF6DE 0%, #FBFFF5 100%);
    position: absolute;
    top: 0;
    height: 450px;
    width: 100%;
    z-index: -1;
} */
.top-header {
}
.ap-main-content {
  margin-top: 2rem;
  
}
.top-nav p {
  color: #000;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}
.ap-heading h2 {
  color: #000;
  font-family: Nunito;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 20px;
  position: relative;
  z-index: 20;
}
.ap-heading p {
  color: #000;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-left: 40px;
}
.ap-main-content .top-title {
  color: #000;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 2rem;
  position: relative;
  z-index: 20;
}
.cur-box {
  border-radius: 13px;
  background: rgba(255, 255, 255, 0.3); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Blur effect */
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  padding: 30px;
  display: block;
  margin-bottom: 100px;
  min-height: 450px;
  border: 1px solid rgba(255, 255, 255, 0.3); /* Optional border */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Optional shadow */
  position: relative;
  z-index: 20;
}
.cur-box-content {
  list-style: none;
  margin-top: 2rem;
  padding-left: 0;
}
.cur-box-content-button {
  margin-bottom: 1rem;
}
.cur-box h4 {
  color: #000;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
/* drack */
._lightMode_ .ap:before {
  display: none;
}
._lightMode_ .top-nav p {
  color: #fff;
}
._lightMode_ .ap-heading h2 {
  color: #fff;
}
._lightMode_ .ap-heading p {
  color: #fff;
}
._lightMode_ .ap-main-content .top-title {
  color: #fff;
}
._lightMode_ .ap-box {
  border-radius: 13px;
  background: #373737;
  padding: 30px;
  margin-bottom: 1.5rem;
}
._lightMode_ .ap-box h4 {
  color: #fff;
}
._lightMode_ .top-header {
  background: #0000;
}
/* all */
.icon-img.courses-button-style {
  flex-wrap: wrap;
  margin-top: 10px;
  justify-content: space-evenly;
}
.ap-box-content-button .courses-button-style {
  font-size: 14px;
}

.coursecard-heading h2{
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 20px;
  color: #333;
  position: relative;
  z-index: 20;
}

.coursecard-heading p{
 
    position: relative;
    z-index: 20;
  }
  
.ap-section{
  margin-top: 80px;
  padding-top: 20px;
}


@media (max-width: 768px) {
  .ap-section{
    margin-top: 0px;
    padding-top: 0px;
  }
}


@media (max-width: 850px) {
  .ap-section{
    margin-top: 0px;
    padding-top: 0px;
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1100px) {

}
