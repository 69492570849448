.key-concept-top-right img {
  width: 200px;
  z-index: 20;
  position: relative;
}
.unit-button img {
  width: 24px;
  margin-right: 5px;
}
.unit-button {
  max-width: 357px;
  width: 100%;
  text-align: start;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #65828b !important;
  border-color: transparent !important; /* Removes the border color */
  opacity: 0.4 !important; /* Sets 40% opacity */
}
.unit-button.btn.show {
  border-radius: 7px 7px 0px 0px;
  background: #777;
  box-shadow: 0px 0px 40px 50px rgba(0, 0, 0, 0.15);
}
.unit-menu.dropdown-menu.show {
  display: flex;
  max-width: 357px;
  width: 100%;
  padding: 10px;
  border-radius: 0px 0px 7px 7px;
  background: #696969;
  transform: translate3d(0px, 48px, 0px) !important;
  border: none;
  box-shadow: 0px 0px 40px 50px rgba(0, 0, 0, 0.15);
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.ap .dropdown-toggle::after {
  background-image: url(../img/icon/down-white-arror.png);
}
.unit-menu.dropdown-menu.show a {
  width: 89px;
  height: 54px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 3px;
  margin: 10px;
}
.ap-box img {
  width: 24px;
}
.right-arror-key {
  text-align: end;
}
.right-arror-key img {
  width: 24px;
}
/* new */
.filter_question_top ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0;
}
.filter_question_top ul button {
  color: #000;
  border-radius: 10px;
  border: 0.2px solid #c5c1c1;
  background-color: #f0f0f0; /* Light gray background */
  /* box-shadow: 2px 2px 1px 0px rgba(63, 168, 26, 0.25); */
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 34px;
  width: 110px;
  position: relative;
  z-index: 20;
}

.filter_question_top ul button:nth-child(1) {
  box-shadow: 2px 2px 1px 0px rgba(63, 168, 26, 0.25);
}
.filter_question_top ul button:nth-child(2) {
  box-shadow: 2px 2px 1px 0px rgba(254, 180, 112, 0.47);
}
.filter_question_top ul button:nth-child(3) {
  box-shadow: 2px 2px 1px 0px #fcb2b2;
}
.filter_question_top ul button:nth-child(4) {
  box-shadow: 2px 2px 0px 0px #007aa0;
  margin-right: 0;
}
.filter_question_top ul button:nth-child(1).active {
  background: #3fa81a40;
  color: #fff !important;
}
.filter_question_top ul button:nth-child(2).active {
  background: #feb47078;
  color: #fff !important;
}
.filter_question_top ul button:nth-child(3).active {
  background: #fcb2b2;
  color: #fff !important;
}
.filter_question_top ul button:nth-child(4).active {
  background: #007aa0;
  color: #fff !important ;
}

._lightMode_ .filter_question_top ul button {
  background: #0000;
  border: 0.2px solid #fff;
}
.question-filter-top-heading-center {
  align-items: center;
  margin-top: 16px;
}
.question-filter-top-heading-center .question-filter-top-heading-right {
  text-align: right;
}
.question-filter-top-heading-center
  .question-filter-top-heading-right
  .btn-popup-button-1 {
  border-radius: 6px;
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  max-width: 150px;
  height: 38px;
  font-size: 18px;
  position: relative;
  color: #000;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.question-main-box-style {
  border-radius: 10px;
  background: linear-gradient(180deg, #dff0f7 0%, #fff 100%);
  width: 100%;
  padding: 20px;
  margin-bottom: 2rem;
}
.question-filter-top-heading h4 {
  color: #000;
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.question-filter-top-heading .progress {
  background-color: #f5fceb;
  height: 27px;
  width: 100%;
  max-width: 314px;
  /* margin-top: 16px; */
  border-radius: 13.5px;
}
.question-filter-top-heading .popup-button-question-page {
  display: flex;
  flex-wrap: wrap;
}
.popup-button-question-page button {
  max-width: 160px;
  margin-right: 30px;
}
.progress-bar.bg-Easy {
  border-radius: 13.5px;
  background-color: #3fa81a40 !important;
  color: #000;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align: start;
  padding-left: 10px;
}
.popup-button-question-page button {
  border-radius: 6px;
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 38px;
  font-size: 18px;
  text-align: start;
  margin-bottom: 24px;
  color: #000;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.popup-button-question-page button:hover {
  color: #000;
}
.btn-popup-button-1 {
  background: #83c5be;
  width: 200px;
}
.btn-popup-button-1:hover {
  background: #83c5be;
}
.btn-popup-button-2 {
  background: #b3d0cd;
}
.btn-popup-button-2:hover {
  background: #b3d0cd;
}
.btn-popup-button-3 {
  background: #edf6f9;
}
.btn-popup-button-3:hover {
  background: #edf6f9;
}
.question-paper-photo {
  margin-top: 20px;
  position: relative;
}
.question-paper-photo img {
  border-radius: 10px;
}
.btn-popup-button-full-img {
  position: absolute;
  top: 0;
  right: 0;
}
.btn-popup-button-full-img img {
  width: 24px;
}
.full-img-popup {
  position: absolute;
  z-index: 1;
  right: 0;
  border: none;
}
.Formula-popup .modal-content {
  background-color: #0000;
  border: none;
}
.Formula-popup .full-img-popup {
  position: absolute;
  z-index: 1;
  right: -20px;
  top: -25px;
  border: none;
}
.Formula-popup .full-img-popup .btn-close {
  background-image: url(../img/icon/Close_round_light.png);
  background-position: center;
  background-size: cover;
  opacity: 1;
}
.progress-bar.bg-Medium {
  background-color: #feb47078 !important;
  border-radius: 13.5px;
  color: #000;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align: start;
  padding-left: 10px;
}
.progress-bar.bg-Hard {
  background-color: #fcb2b2 !important;
  border-radius: 13.5px;
  color: #000;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align: start;
  padding-left: 10px;
}
.question-left a {
  width: 100%; /* Makes the button take the full width of its container */
  max-width: 350px; /* Set a fixed width */
  height: 70px; /* Increased fixed height */
  background-color: rgba(
    223,
    240,
    247,
    0.7
  ); /* Solid #DFF0F7 color with 70% opacity */
  border: none; /* Remove the border */
  border-radius: 5px; /* Rounded edges */
  padding: 10px 20px; /* Increased padding for better spacing */
  color: #333; /* Text color */
  font-weight: 600; /* Make the font bold */
  font-size: 18px;
  text-align: left; /* Align text to the left */
  transition: background-color 0.3s ease, transform 0.1s ease; /* Add a transition for hover and press effects */
  margin-bottom: 2vh;
  display: flex; /* Use flexbox to align items */
  align-items: center; /* Align items vertically to the center */
  cursor: pointer; /* Change cursor to pointer on hover */
}

.question-left a:hover {
  background-color: rgba(223, 240, 247, 1); /* Darken background on hover */
  
}

.question-left a:active {
  background-color: rgba(200, 220, 230, 1); /* Darken more when pressed */
  transform: scale(0.98); /* Slightly shrink the button on press */
}

.question-left {
  margin-right: 10px;
  position: sticky;
  top: 70px;
  width: 100%;
  height: 100vh;
  overflow-y: scroll; /* Allows vertical scrolling */
  overflow-x: hidden; /* Disables horizontal scrolling */
  padding-right: 15px; /* Adds padding to the right to create space between the buttons and the scrollbar */
}

/* dark */
._lightMode_ .filter_question_top ul button {
  color: #fff !important;
}
._lightMode_ .question-left a {
  color: #fff;
}
._lightMode_ .question-main-box-style {
  background: #494949;
}
._lightMode_ .question-filter-top-heading h4 {
  color: #fff;
}
/* new */

/* Styling for the Accordion Container */
.accordion-container {
  background-color: rgba(255, 255, 255, 0.7); /* Default background */
  padding: 10px; /* Padding inside the container */
  border-radius: 15px; /* Rounded corners for the container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin-bottom: 20px; /* Space below the container */
}

/* Styling for the Accordion Item */
.custom-accordion-item {
  border: none; /* Remove any borders */
  background-color: transparent; /* Ensure the item background is transparent */
  margin-bottom: 15px; /* Add space between items */
  border-radius: 10px; /* Add rounded corners to the entire item */
}

/* Styling for the Accordion Header */
.accordion-button {
  background-color: rgba(223, 240, 247, 0.7); /* Default background */
  color: rgb(0, 0, 0); /* Default text color */
  font-size: 1.2rem; /* Font size */
  font-family: "Nunito"; /* Font family */
  font-weight: bold; /* Bold text */
  padding: 15px; /* Padding inside the header */
  border-radius: 10px; /* More rounded corners */
  transition: background-color 0.3s ease-in-out;
  border: none; /* Remove border */
  margin-bottom: 10px; /* Add space between buttons */
}

/* When the Accordion is Expanded/Selected */
.accordion-button:not(.collapsed) {
  background-color: rgba(215, 255, 192, 0.7); /* Light green background */
  color: #000000; /* Change text color when selected */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow when expanded */
  border-radius: 10px; /* Ensure rounded corners when expanded */
}

/* Hover Effect */
.accordion-button:hover {
  background-color: rgba(
    215,
    255,
    192,
    0.7
  ); /* Slightly different hover background */
  color: #003366; /* Darker text color on hover */
}

/* Styling for the Accordion Body */
.accordion-body {
  background-color: #f8f9fa; /* Light background for the body */
  color: #333; /* Text color */
  font-size: 1rem; /* Font size */
  padding: 20px; /* Padding inside the body */
  border-radius: 10px; /* More rounded corners */
  margin-bottom: 10px; /* Space between accordion items */
}

/* When the Accordion Body is Expanded */
.accordion-body.show {
  background-color: #e3f2fd; /* Light blue background when expanded */
}

.breadcrumb li a {
  color: #000;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}
.breadcrumb-item.active {
  color: #000;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}

.full-width button {
  border-radius: 8px !important;
  background: #f6f6f6;
}

.full-width {
  max-width: 100% !important;
}

.unit-question-link {
  max-width: 320px;
  margin-right: 0;
  width: 100%;
  margin-left: auto;
}
.unit-question-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid #f6f6f6;
  background: #f6f6f6;
  padding: 0px 29px;
  height: 60px;
  color: #000;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
}
.unit-question-link img {
  width: 24px;
}
#example-collapse-text h4 {
  color: #000;
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.question-video-img {
  margin-top: 31px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-img {
  position: absolute;
  width: 192px;
}
#example-collapse-text p {
  color: #000;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
/* dark */
._lightMode_ #example-collapse-text h4 {
  color: #fff;
}
._lightMode_ #example-collapse-text p {
  color: #fff;
}

._lightMode_ .unit-question-link {
  border: 1px solid #494949;
  background: #494949;
  color: #fff;
}
._lightMode_ .full-width button {
  background: #494949;
  color: #fff;
}
._lightMode_ .unit-question-link img {
  display: block;
}
._lightMode_ .breadcrumb li a {
  color: #fff;
}
.unit-question-link img.d {
  display: block;
}
.unit-question-link img {
  display: none;
}
._lightMode_ .breadcrumb-item.active {
  color: #fff;
}
._lightMode_ .breadcrumb-item + .breadcrumb-item::before {
  color: #fff;
}
.key-concept-top-right.text-center img {
  display: none;
}
.key-concept-top-right.text-center img.d {
  display: block;
  margin: auto;
}
._lightMode_ .key-concept-top-right.text-center img {
  display: block;
  margin: auto;
}

.popup-img-img img {
  width: 100% !important;
  height: auto !important;
}
/* new style 6-now-2023 */
.unit-page-box-box-1.video_full {
  width: 100%;
  margin-bottom: 2rem;
}
.unit-page-box-box-1.video_full #VisaChipCardVideo {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 8px;
}
/* new */
.question-filter-top-heading {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
/*  */

.question-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.align-right-button {
  margin-left: auto; /* Pushes the button to the right */
}

.difficulty-icon {
  margin-left: 10px; /* Space between the question number and the icon */
  width: 35px; /* Adjust size as needed */
  height: 35px;
  vertical-align: middle; /* Aligns the icon vertically with the text */
}

.coming-soon {
  margin: 80px 0;  /* Adjust the margin top/bottom as needed */
}

.coming-soon h1 {
  font-size: 3rem;  /* Big font size */
  color: #5b9311;   /* Use your theme color */
  font-family: Nunito, sans-serif;
}


/* Mobile Responsive Adjustments */
@media (max-width: 767px) {
  /* Main Section: Remove extra top spacing */
  .questionbank-section {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  

  /* Adjust Heading Fonts */
  .ap-heading h2 {
    font-size: 1.8rem; /* slightly smaller on mobile */
    margin: 10px 0;
  }

  .top-title {
    font-size: 1.5rem;
    text-align: center;
  }

  /* Breadcrumb: Reduce text size */
  .breadcrumb li a,
  .breadcrumb-item.active {
    font-size: 14px;
  }

  /* Key Concept Header Images: Scale down images */
  .key-concept-top-right img {
    width: 150px;
  }

  /* Dropdown Button: Adjust margins and width */
  .unit-button {
    margin-bottom: 20px;
    max-width: 100%;
  }

  /* Dropdown Menu Items: Make them slightly smaller and more spaced */
  .unit-menu.dropdown-menu.show a {
    width: 80px;
    height: 45px;
    font-size: 14px;
    margin: 5px;
  }

  /* Optionally Hide or Adjust Decorative Images */
  .splatter-image-right-2 {
    display: none; /* hide decorative background on mobile, or adjust as needed */
  }

  /* Adjust Bottom Margins for Sections */
  .mb-120 {
    margin-bottom: 60px;
  }

  /* If the padding bottom on rows is too large */
  .pb-120 {
    padding-bottom: 60px;
  }
}


@media (max-width: 850px) { 
  /* Adjust the logo size */
  .top-title {
    position: relative;
    z-index: 100;
    margin-bottom: 30px;
   
  }

  mjx-math {
    white-space: normal !important;
    overflow-wrap: break-word;
    word-wrap: break-word;

  }

  #example-collapse-text h4 {
    font-size: 1.2rem;  /* adjust this value as needed */
  }
  #example-collapse-text p {
    font-size: 0.9rem;  /* if needed */
  }
  mjx-math {
    font-size: 0.9rem !important; /* force a smaller size for MathJax output */
  }

  #example-collapse-text td img {
    width: auto !important;
    max-width: 100% !important;
    height: auto !important;
    display: block;
  }

  #example-collapse-text table,
  #example-collapse-text td,
  #example-collapse-text th {
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
    background: transparent !important;
    box-shadow: none !important;
    border-spacing: 0 !important;
    outline: none !important;
  }
  
  /* Ensure the table collapses its borders and spacing */
  #example-collapse-text table {
    border-collapse: collapse !important;
  }

  #example-collapse-text {
    overflow-x: hidden;
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1100px) {
  /* Adjust the logo size */
  .top-title {
    position: relative;
    z-index: 100;
    margin-bottom: 30px;
}
}



