.privacypolicy-section {
  margin-top: 80px;
  padding-top: 20px;
}

.Terms-Of-Use {
  margin-top: 80px;
  padding-top: 20px;
}

.Cookies-Notice {
  margin-top: 80px;
  padding-top: 20px;
}

.Personal-Info{
    margin-top: 80px;
    padding-top: 20px;
}