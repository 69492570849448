.payment-table-header {
    margin-bottom: 20px;
  }
  
  /* Mobile-specific adjustments */
  @media (max-width: 768px) {
    /* Optional: add spacing between cells */
    .Billing_table {
      border-collapse: separate;
      border-spacing: 10px; /* Adjust spacing between cells as needed */
    }
    
    .Billing_table th,
    .Billing_table td {
      font-size: 14px;
      padding: 8px 16px; /* Increase horizontal padding for more breathing room */
      white-space: nowrap; /* Prevents the text from wrapping */
      min-width: 80px; /* Ensures a minimum width for each cell */
    }
  }

  @media (max-width: 850px) {
    .Billing_table {
      border-collapse: separate;
      border-spacing: 10px; /* Adjust spacing between cells as needed */
    }
    
    .Billing_table th,
    .Billing_table td {
      font-size: 14px;
      padding: 8px 16px; /* Increase horizontal padding for more breathing room */
      white-space: nowrap; /* Prevents the text from wrapping */
      min-width: 80px; /* Ensures a minimum width for each cell */
    }
  }
  
  @media only screen and (min-width: 1001px) and (max-width: 1100px) {
    .Billing_table {
      border-collapse: separate;
      border-spacing: 10px; /* Adjust spacing between cells as needed */
    }
    
    .Billing_table th,
    .Billing_table td {
      font-size: 14px;
      padding: 8px 16px; /* Increase horizontal padding for more breathing room */
      white-space: nowrap; /* Prevents the text from wrapping */
      min-width: 80px; /* Ensures a minimum width for each cell */
    }
  }
  