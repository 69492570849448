.brend {
    background: linear-gradient(
    -90deg, /* Gradient direction */
    rgba(29, 168, 255, 0.6) 0%,   /* 60% opacity for #1DA8FF */
    rgba(177, 111, 255, 0.6) 33%, /* 60% opacity for #B16FFF */
    rgba(246, 97, 134, 0.6) 60%,  /* 60% opacity for #F66186 */
    rgba(254, 169, 6, 0.6) 100%   /* 60% opacity for #FEA906 */
  ) !important;
    padding: 22px;
    z-index: 20;
    position: relative;
}
.brand-box img {
    width: 104px;
}
.brand-box {
    display: flex;
    align-items: center;
    height: 100%;
    margin-bottom: 2rem;
}
.brand-box p {
    color: #000;
    font-family: Nunito;
    font-size: 27px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 10px;
    margin-bottom: 0;
}
.brand-box-2 {
    display: flex;
    align-items: center;
    height: 100%;
    margin-bottom: 2rem;
}
/* .brand-box-2 img {
    height: 104px;
} */